import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Components/HostBackend.js';
import { FilterPark } from '../../Components/Public/Filters/FilterPark.js';
import { FilterAttractions } from '../../Components/Public/Filters/FilterAttractions.js';
import { FilterCategory } from '../../Components/Public/Filters/FilterCategory.js';
import { CountUnivers } from '../../Components/Public/Filters/CountUnivers.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { CreatedAt } from '../../Components/Public/Document/CreatedAt.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockInfos } from '../../Components/Public/Blocks/BlockInfos.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';
import { GetIconCode } from '../../Components/Public/Functions/GetIconCode.js';

export const Park = (props) => {
    const hosting = HostBackend();
    const [parks, setParks] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasParks = await fetch(`${hosting}/park_by_id?id=${props.id}`);
            const parks = await datasParks.json();
            setParks(parks);
        };
        fetchData();
    }, []);
    const park = FilterPark(parks);
    const attractions = FilterAttractions(props.nav, props.id);
    const categories = FilterCategory('park', props.nav, props.id);
    const countUnivers = CountUnivers(attractions);
    return (
        <div className="park">
            <Carrousel datas={parks} />
            <BreadCrumb />
            <section className="infos">
                {park.map((p, i) => {
                    return (
                        <div key={i + 'i'} className="bloc-infos">
                            <BlockLogo slug={p.pslug} legend={p.pname} />
                            <BlockInfos univers={parks} attractions={attractions} categories={categories} count={countUnivers} />
                        </div>
                    )
                })}
                {park.map((p, i) => {
                    return (
                        <div key={`d-${i}`} className="description">
                            <div className="block-title">
                                <CreatedAt date={'1992-04-12'} />
                                <div className="title">
                                    <div className="title-author">{'Walt Disney Imagineering'}</div>
                                    <div className="title-name">{p.pname}</div>
                                    <a className="title-category" href={'/'} alt={'home'}>
                                        <div className="code"><i className={GetIconCode('CT')}></i></div>
                                        <div className="name">{'Parc à thèmes'}</div>
                                    </a>
                                </div>
                            </div>
                            <p>{p.description}</p>
                        </div>
                    )
                })}
            </section>
        </div>
    )
}