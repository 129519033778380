/* datas */
import { FilesImagesDefault } from './FilesImagesDefault.js';

import { Aladdin } from './Origins/Aladdin.js';
import { AliceAuPaysDesMerveilles } from './Origins/AliceAuPaysDesMerveilles.js';
import { AtlantideLEmpirePerdu } from './Origins/AtlantideLEmpirePedu.js';
import { Bambi } from './Origins/Bambi.js';
import { BlancheNeige } from './Origins/BlancheNeige.js';
import { Cars } from './Origins/Cars.js';
import { Cendrillon } from './Origins/Cendrillon.js';
import { ChickenLittle } from './Origins/ChickenLittle.js';
import { Coco } from './Origins/Coco.js';
import { Dumbo } from './Origins/Dumbo.js';
import { Encanto } from './Origins/Encanto.js';
import { FrereDesOurs } from './Origins/FrereDesOurs.js';
import { Hercule } from './Origins/Hercule.js';
import { IndianaJonesEtLeTempleMaudit } from './Origins/IndianaJonesEtLeTempleMaudit.js';
import { LaBelleAuBoisDormant } from './Origins/LaBelleAuBoisDormant.js';
import { LaBelleEtLaBete } from './Origins/LaBelleEtLaBete.js';
import { LaHaut } from './Origins/LaHaut.js';
import { LaPetiteSirene } from './Origins/LaPetiteSirene.js';
import { LaPrincesseEtLaGrenouille } from './Origins/LaPrincesseEtLaGrenouille.js';
import { LaReineDesNeiges } from './Origins/LaReineDesNeiges.js';
import { LeBossuDeNotreDame } from './Origins/LeBossuDeNotreDame.js';
import { LeLivreDeLaJungle } from './Origins/LeLivreDeLaJungle.js';
import { LeMondeDeNemo } from './Origins/LeMondeDeNemo.js';
import { LeRoiLion } from './Origins/LeRoiLion.js';
import { LesAristochats } from './Origins/LesAristochats.js';
import { LesAventuresDeBernardEtBianca } from './Origins/LesAventuresDeBernardEtBianca.js';
import { LesIndestructibles } from './Origins/LesIndestructibles.js';
import { LesTroisPetitsCochons } from './Origins/LesTroisPetitsCochons.js';
import { LEtrangeNoelDeMonsieurJack } from './Origins/LEtrangeNoelDeMonsieurJack.js';
import { LiloEtStitch } from './Origins/LiloEtStitch.js';
import { Marvel } from './Origins/Marvel.js';
import { MaryPoppins } from './Origins/MaryPoppins.js';
import { MilleEtUnePattes } from './Origins/MilleEtUnePattes.js';
import { MonstresEtCie } from './Origins/MontresEtCie.js';
import { Mulan } from './Origins/Mulan.js';
import { PeterPan } from './Origins/PeterPan.js';
import { Pinocchio } from './Origins/Pinocchio.js';
import { PiratesOfTheCaribbean } from './Origins/PiratesOfTheCaribbean.js';
import { Pocahontas } from './Origins/Pocahontas.js';
import { Raiponce } from './Origins/Raiponce.js';
import { Ratatouille } from './Origins/Ratatouille.js';
import { Rebelle } from './Origins/Rebelle.js';
import { RiriFifiLoulou } from './Origins/RiriFifiLoulou.js';
import { RobinDesBois } from './Origins/RobinDesBois.js';
import { StarWars } from './Origins/StarWars.js';
import { Tarzan } from './Origins/Tarzan.js';
import { ToyStory } from './Origins/ToyStory.js';
import { Vaiana } from './Origins/Vaiana.js';
import { ViceVersa } from './Origins/ViceVersa.js';
import { VoltStarMalgreLui } from './Origins/VoltStarMalgreLui.js';
import { WaltDisneyImagineering } from './Origins/WaltDisneyImagineering.js';
import { Winnie } from './Origins/Winnie.js';
import { WishAshaEtLaBonneEtoile } from './Origins/WishAshaEtLaBonneEtoile.js';
import { Zootopie } from './Origins/Zootopie.js';


export const FilesImagesOrigin = () => {
    let datas = [];
    const origin = window.location.href.split('/')[4];
    if (origin === 'aladdin') { datas = Aladdin(); }
    if (origin === 'alice-au-pays-des-merveilles') { datas = AliceAuPaysDesMerveilles(); }
    if (origin === 'atlantide-l-empire-perdu') { datas = AtlantideLEmpirePerdu(); }
    if (origin === 'bambi') { datas = Bambi(); }
    if (origin === 'blanche-neige') { datas = BlancheNeige(); }
    if (origin === 'cars') { datas = Cars(); }
    if (origin === 'cendrillon') { datas = Cendrillon(); }
    if (origin === 'chicken-little') { datas = ChickenLittle(); }
    if (origin === 'coco') { datas = Coco(); }
    if (origin === 'dumbo') { datas = Dumbo(); }
    if (origin === 'encanto') { datas = Encanto(); }
    if (origin === 'frere-des-ours') { datas = FrereDesOurs(); }
    if (origin === 'hercule') { datas = Hercule(); }
    if (origin === 'indiana-jones-et-le-temple-maudit') { datas = IndianaJonesEtLeTempleMaudit(); }
    if (origin === 'la-belle-au-bois-dormant') { datas = LaBelleAuBoisDormant(); }
    if (origin === 'la-belle-et-la-bete') { datas = LaBelleEtLaBete(); }
    if (origin === 'la-haut') { datas = LaHaut(); }
    if (origin === 'la-petite-sirene') { datas = LaPetiteSirene(); }
    if (origin === 'la-princesse-et-la-grenouille') { datas = LaPrincesseEtLaGrenouille(); }
    if (origin === 'la-reine-des-neiges') { datas = LaReineDesNeiges(); }
    if (origin === 'le-bossu-de-notre-dame') { datas = LeBossuDeNotreDame(); }
    if (origin === 'le-livre-de-la-jungle') { datas = LeLivreDeLaJungle(); }
    if (origin === 'le-monde-de-nemo') { datas = LeMondeDeNemo(); }
    if (origin === 'le-roi-lion') { datas = LeRoiLion(); }
    if (origin === 'les-aristochats') { datas = LesAristochats(); }
    if (origin === 'les-aventures-de-bernard-et-bianca') { datas = LesAventuresDeBernardEtBianca(); }
    if (origin === 'les-indestructibles') { datas = LesIndestructibles(); }
    if (origin === 'les-trois-petits-cochons') { datas = LesTroisPetitsCochons(); }
    if (origin === 'l-etrange-noel-de-monsieur-jack') { datas = LEtrangeNoelDeMonsieurJack(); }
    if (origin === 'lilo-et-stitch') { datas = LiloEtStitch(); }
    if (origin === 'marvel') { datas = Marvel(); }
    if (origin === 'mary-poppins') { datas = MaryPoppins(); }
    if (origin === 'mille-et-une-pattes') { datas = MilleEtUnePattes(); }
    if (origin === 'monstres-et-cie') { datas = MonstresEtCie(); }
    if (origin === 'mulan') { datas = Mulan(); }
    if (origin === 'peter-pan') { datas = PeterPan(); }
    if (origin === 'pinocchio') { datas = Pinocchio(); }
    if (origin === 'pirates-of-the-caribbean') { datas = PiratesOfTheCaribbean(); }
    if (origin === 'pocahontas') { datas = Pocahontas(); }
    if (origin === 'raiponce') { datas = Raiponce(); }
    if (origin === 'ratatouille') { datas = Ratatouille(); }
    if (origin === 'rebelle') { datas = Rebelle(); }
    if (origin === 'riri-fifi-loulou') { datas = RiriFifiLoulou(); }
    if (origin === 'robin-des-bois') { datas = RobinDesBois(); }
    if (origin === 'star-wars') { datas = StarWars(); }
    if (origin === 'tarzan') { datas = Tarzan(); }
    if (origin === 'toy-story') { datas = ToyStory(); }
    if (origin === 'vaiana') { datas = Vaiana(); }
    if (origin === 'vice-versa') { datas = ViceVersa(); }
    if (origin === 'volt-star-malgre-lui') { datas = VoltStarMalgreLui(); }
    if (origin === 'walt-disney-imagineering') { datas = WaltDisneyImagineering(); }
    if (origin === 'winnie') { datas = Winnie(); }
    if (origin === 'wish-asha-et-la-bonne-etoile') { datas = WishAshaEtLaBonneEtoile(); }
    if (origin === 'zootopie') { datas = Zootopie(); }
    return datas ? datas : FilesImagesDefault();
}