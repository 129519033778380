import { ButtonDelete } from "../Form/Buttons/ButtonDelete.js";
import { ButtonUpdate } from "../Form/Buttons/ButtonUpdate.js";

export const Attributes = (props) => {
    switch (props.uri) {
        case 'category':
        case 'description':
        case 'evacuation':
        case 'handicap':
        case 'height':
        case 'interest':
        case 'origin':
        case 'photopass':
        case 'pmr':
        case 'premieraccess':
        case 'public':
        case 'sensory':
        case 'singlerider':
        case 'sort':
            return (
                props.datas.map((e,i) => {
                    return (
                        <div key={`e${i}`} className="rows">
                            <div className="id">{i+1}</div>
                            <div className="item">{e.name} <span>({e.id})</span></div>
                            <ButtonDelete id={e.id} uri={props.uri} />
                            <ButtonUpdate id={e.id} uri={props.uri} />
                        </div>
                    )
                }));
        default:
            return (
                <></>
            )
    }

}