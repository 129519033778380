/*
@param slug
@param legend
*/

import { GetIconCode } from "../Functions/GetIconCode.js";

export const BlockLogo = (props) => {
    const uri = window.location.href;
    if (uri.includes('attraction')) {
        /* attraction */
        return (
            <div className="logo">
                <div className={`icon icon-${props.slug}`}></div>
                <div className="legend">
                    <div className="title">{props.legend}</div>
                    <a className="item" href={props.category[0]} alt={props.category[1]}>
                        <div className="ccode"><i className={GetIconCode(props.category[0])}></i></div>
                        <div className="cname">{props.category[1]}</div>
                    </a>
                    <a className="item" href={props.origin[0]} alt={props.origin[1]}>
                        <div className="ccode"><i className={GetIconCode('OR')}></i></div>
                        <div className="cname">{props.origin[1]}</div>
                    </a>
                </div>
            </div>
        )
    } else if (uri.includes('park')) {
        /* park | univers */
        return (
            <div className="logo">
                <div className={`icon icon-${props.slug}`}></div>
                <div className="legend">
                    <div className="title">{props.legend}</div>
                </div>
            </div>
        )
    } else if (uri.includes('experiences')) {
        /* experiences */
        return (
            <div className="logo">
                <div className="icon">
                    <img src={props.path} title={props.name} />
                </div>
                <div className="legend">
                    <div className="category-title">{props.code}</div>
                    <div className="title">{props.name}</div>
                    <div className="category-title">{props.cat}</div>
                </div>
            </div>
        )
    } else {
        /* home | origins | categories */
        return (
            <a className="logo" href={props.href} alt={props.legend}>
                <div className={`icon icon-${props.slug}`}></div>
                <div className="legend">
                    <div className="title">{props.legend}</div>
                </div>
            </a>
        )
    }
}