export const Adventureland = (slug) => {
    let datas;
    if (slug === 'adventure-isle') {
        datas = require.context('../../../../assets/images/attractions/park/adventure-isle/img', false, /\.(png|jpe?g)$/);
    }
    if (slug === 'aladdin') {
        datas = require.context('../../../../assets/images/attractions/park/aladdin/img', false);
    }
    if (slug === 'mowgli') {
        datas = require.context('../../../../assets/images/attractions/park/mowgli/img', false);
    }
    if (slug === 'baloo') {
        datas = require.context('../../../../assets/images/attractions/park/baloo/img', false);
    }
    if (slug === 'bambouseraie') {
        datas = require.context('../../../../assets/images/attractions/park/bambouseraie/img', false);
    }
    if (slug === 'capitaine-crochet') {
        datas = require.context('../../../../assets/images/attractions/park/capitaine-crochet/img', false);
    }
    if (slug === 'indiana-jones-et-le-temple-du-peril') {
        datas = require.context('../../../../assets/images/attractions/park/indiana-jones-et-le-temple-du-peril/img', false);
    }
    if (slug === 'jack-sparrow') {
        datas = require.context('../../../../assets/images/attractions/park/jack-sparrow/img', false);
    }
    if (slug === 'jafar') {
        datas = require.context('../../../../assets/images/attractions/park/jafar/img', false);
    }
    if (slug === 'jasmine') {
        datas = require.context('../../../../assets/images/attractions/park/jasmine/img', false);
    }
    if (slug === 'la-cabane-des-robinson') {
        datas = require.context('../../../../assets/images/attractions/park/la-cabane-des-robinson/img', false);
    }
    if (slug === 'la-plage-des-pirates') {
        datas = require.context('../../../../assets/images/attractions/park/la-plage-des-pirates/img', false);
    }
    if (slug === 'le-galion-des-pirates') {
        datas = require.context('../../../../assets/images/attractions/park/le-galion-des-pirates/img', false);
    }
    if (slug === 'le-genie') {
        datas = require.context('../../../../assets/images/attractions/park/le-genie/img', false);
    }
    if (slug === 'le-passage-enchante-d-aladdin') {
        datas = require.context('../../../../assets/images/attractions/park/le-passage-enchante-d-aladdin/img', false);
    }
    if (slug === 'le-roi-louie') {
        datas = require.context('../../../../assets/images/attractions/park/le-roi-louie/img', false);
    }
    if (slug === 'mouche') {
        datas = require.context('../../../../assets/images/attractions/park/mouche/img', false);
    }
    if (slug === 'peter-pan') {
        datas = require.context('../../../../assets/images/attractions/park/peter-pan/img', false);
    }
    if (slug === 'wendy') {
        datas = require.context('../../../../assets/images/attractions/park/wendy/img', false);
    }
    if (slug === 'pirates-of-caribbean') {
        datas = require.context('../../../../assets/images/attractions/park/pirates-of-caribbean/img', false);
    }
    if (slug === 'rafiki') {
        datas = require.context('../../../../assets/images/attractions/park/rafiki/img', false);
    }
    if (slug === 'tetrapanax') {
        datas = require.context('../../../../assets/images/attractions/park/tetrapanax/img', false);
    }
    if (slug === 'tic-et-tac') {
        datas = require.context('../../../../assets/images/attractions/park/tic-et-tac/img', false);
    }
    if (slug === 'timon') {
        datas = require.context('../../../../assets/images/attractions/park/timon/img', false);
    }
    if (slug === 'abu') {
        datas = require.context('../../../../assets/images/attractions/park/abu/img', false);
    }
    if (slug === 'le-sultan') {
        datas = require.context('../../../../assets/images/attractions/park/le-sultan/img', false);
    }
    if (slug === 'tarzan') {
        datas = require.context('../../../../assets/images/attractions/park/tarzan/img', false);
    }
    if (slug === 'tokina') {
        datas = require.context('../../../../assets/images/attractions/park/tarzan/img', false);
    }
    if (slug === 'kala') {
        datas = require.context('../../../../assets/images/attractions/park/kala/img', false);
    }
    if (slug === 'kerchak') {
        datas = require.context('../../../../assets/images/attractions/park/kerchak/img', false);
    }
    if (slug === 'jane-porter') {
        datas = require.context('../../../../assets/images/attractions/park/jane-porter/img', false);
    }
    if (slug === 'lewis-robinson') {
        datas = require.context('../../../../assets/images/attractions/park/lewis-robinson/img', false);
    }
    if (slug === 'wilbur-robinson') {
        datas = require.context('../../../../assets/images/attractions/park/wilbur-robinson/img', false);
    }
    if (slug === 'michael-goob-yagoobian') {
        datas = require.context('../../../../assets/images/attractions/park/michael-goob-yagoobian/img', false);
    }
    return datas.keys().map(image => datas(image));
}

