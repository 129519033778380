export const AvengersCampus = (slug) => {
    let datas;
    if (slug === 'ant-man') {
        datas = require.context('../../../../assets/images/attractions/studio/ant-man/img', true);
    }
    if (slug === 'avengers-assemble-flight-force') {
        datas = require.context('../../../../assets/images/attractions/studio/avengers-assemble-flight-force/img', true);
    }
    if (slug === 'avengers-power-the-night') {
        datas = require.context('../../../../assets/images/attractions/studio/avengers-power-the-night/img', true);
    }
    if (slug === 'black-panther') {
        datas = require.context('../../../../assets/images/attractions/studio/black-panther/img', true);
    }
    if (slug === 'captain-america') {
        datas = require.context('../../../../assets/images/attractions/studio/captain-america/img', true);
    }
    if (slug === 'captain-marvel') {
        datas = require.context('../../../../assets/images/attractions/studio/captain-marvel/img', true);
    }
    if (slug === 'ceremonie-heroique') {
        datas = require.context('../../../../assets/images/attractions/studio/ceremonie-heroique/img', true);
    }
    if (slug === 'crane-rouge') {
        datas = require.context('../../../../assets/images/attractions/studio/crane-rouge/img', true);
    }
    if (slug === 'docteur-strange') {
        datas = require.context('../../../../assets/images/attractions/studio/docteur-strange/img', true);
    }
    if (slug === 'gamora') {
        datas = require.context('../../../../assets/images/attractions/studio/gamora/img', true);
    }
    if (slug === 'groot') {
        datas = require.context('../../../../assets/images/attractions/studio/groot/img', true);
    }
    if (slug === 'guepe') {
        datas = require.context('../../../../assets/images/attractions/studio/guepe/img', true);
    }
    if (slug === 'hero-training-center') {
        datas = require.context('../../../../assets/images/attractions/studio/hero-training-center/img', true);
    }
    if (slug === 'hulk') {
        datas = require.context('../../../../assets/images/attractions/studio/hulk/img', true);
    }
    if (slug === 'iron-man') {
        datas = require.context('../../../../assets/images/attractions/studio/iron-man/img', true);
    }
    if (slug === 'l-alliance-des-avengers') {
        datas = require.context('../../../../assets/images/attractions/studio/l-alliance-des-avengers/img', true);
    }
    if (slug === 'l-incroyable-spider-man') {
        datas = require.context('../../../../assets/images/attractions/studio/l-incroyable-spider-man/img', true);
    }
    if (slug === 'les-gardiens-de-la-galaxie-dance-challenge') {
        datas = require.context('../../../../assets/images/attractions/studio/les-gardiens-de-la-galaxie-dance-challenge/img', true);
    }
    if (slug === 'les-guerrieres-du-wakanda') {
        datas = require.context('../../../../assets/images/attractions/studio/les-guerrieres-du-wakanda/img', true);
    }
    if (slug === 'loki') {
        datas = require.context('../../../../assets/images/attractions/studio/loki/img', true);
    }
    if (slug === 'sorciere-rouge') {
        datas = require.context('../../../../assets/images/attractions/studio/sorciere-rouge/img', true);
    }
    if (slug === 'spiderman') {
        datas = require.context('../../../../assets/images/attractions/studio/spiderman/img', true);
    }
    if (slug === 'star-lord') {
        datas = require.context('../../../../assets/images/attractions/studio/star-lord/img', true);
    }
    if (slug === 'thor') {
        datas = require.context('../../../../assets/images/attractions/studio/thor/img', true);
    }
    if (slug === 'veuve-noire') {
        datas = require.context('../../../../assets/images/attractions/studio/veuve-noire/img', true);
    }
    if (slug === 'web-slingers') {
        datas = require.context('../../../../assets/images/attractions/studio/web-slingers/img', true);
    }
    return datas.keys().map(image => datas(image));
}