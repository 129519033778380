import { useState, useEffect } from 'react';
import { FilterBkgColor } from '../../Public/Filters/FilterBkgColor';
/* datas */
import { HostBackend } from '../../../Components/HostBackend.js';

export const FindAttractions = () => {
    const hosting = HostBackend();
    const uri = window.location.href;
    const bkgNav = FilterBkgColor();
    const [show0, setShow0] = useState('hide');
    const [show1, setShow1] = useState('hide');
    const [find, setFind] = useState('');
    const [findAttractions, setFindAttractions] = useState([]);
    useEffect(() => {
        const fetchtheData = async () => {
            const dataAttractions = await fetch(`${hosting}/find_attraction?find=${find}`);
            const findAttractions = await dataAttractions.json();
            setFindAttractions(findAttractions);
        };
        fetchtheData();
    }, [find]);
    if (!uri.includes('connexion') && !uri.includes('dashboard')) {
        return (
            <div className={`finder ${bkgNav}`}>
                <div className="label" onClick={(e) => { show0 === 'hide' ? setShow0('show') : setShow0('hide'); setShow1('hide'); }}>
                    Rechercher<i className="fa fa-search"></i>
                </div>
                <div className={`search ${show0}`}>
                    <input type="text" max="50" value={find} name="find" onChange={(e) => { setFind(e.target.value); setShow1('show') }} />
                </div>
                <section className={`infos ${show1}`}>
                    <h2>Résultat</h2>
                    <ul className="result" >
                        {findAttractions.map((el, i) => {
                            if (uri.includes('admin') && sessionStorage.getItem('token') !== null) {
                                return (
                                    <li key={`f${i}`}><a href={`/admin/entity/update/attraction?id=${el.id}`}>{el.name}</a></li>
                                )
                            } else {
                                return (
                                    <li key={`f${i}`}><a href={el.route}>{el.name}</a></li>
                                )
                            }
                        })}
                    </ul>
                </section>
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}