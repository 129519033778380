/*
@props label string
@props datas array
*/
export const BlockOrigins = (props) => {
    console.log('BlockOrigins',props.datas)
    return (
        <ul className="list">
            <li className="items-1">
                <div className="total">
                    <div>{`${props.datas.length} origines`}</div>
                    <div>Attractions par</div>
                    <div>origines</div>
                </div>
                {props.datas.map((o) => {
                    let counter = props.count ? props.count[o.oslug] : '-';
                    return (
                        <a key={`${o.oslug}`} href={o.oroute} alt={o.oname} className="item">
                            <div className="ccode">{`${o.ocreated.split('-')[0]}`}</div>
                            <div className="cname">{`${o.oname} (${counter})`}</div>
                        </a>
                    )
                })}
            </li>
        </ul>
    )
}