export const LoadTitlePage = () => {
    const title = document.getElementsByTagName("title")[0];
    const url = window.location.href;
    const uri = url.split('/');
    if (url.includes('admin')) {
        /* backoffice */
        let host = window.location.hostname;
        title.innerHTML = uri[6] ? `admin - ${uri[6]} | ${host}` : `admin - ${uri[4]} | ${host}`;
    } else {
        /* frontoffice */
        let host = 'Disneyland Paris';
        if (uri[7] && uri[5] && uri[3]) {
            /* attraction */
            title.innerHTML = `${uri[7]} ${uri[5]} | ${host}`;
        } else if (uri[5] && uri[3]) {
            /* univers */
            title.innerHTML = `${uri[5]} | ${host}`;
        } else if (uri[3]) {
            /* park - origin - category */
            title.innerHTML = `${uri[3]} | ${host}`;
        } else {
            /* home */
            title.innerHTML = `attractions univers parks | ${host}`;
        }
    }
}
