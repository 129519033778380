import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Components/HostBackend.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { CreatedAt } from '../../Components/Public/Document/CreatedAt.js';

export const Origins = () => {
    const hosting = HostBackend();
    const [origins, setOrigins] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const originsDatas = await fetch(`${hosting}/origins`);
            const origins = await originsDatas.json();
            setOrigins(origins);
        };
        fetchData();
    }, []);
    const description = origins[0] ? origins[0].description : '';
    const total = origins.length > 0 ? `(${origins.length} origines)` : '';
    return (
        <div className="origins">
            <BreadCrumb />
            <section className="infos">
                <div className="list">
                    {origins.map((m, i) => {
                        return (
                            <a className="logo" href={m.route} alt={m.name} key={`o-${i}`}>
                                <div className={`icon origin-${m.slug}`}></div>
                                <div className="legend">
                                    <div className="date">{m.created.split('-')[0]}</div>
                                    <div className="title">{m.name}</div>
                                </div>
                            </a>
                        )
                    })}
                </div>
                <div className="description">
                    <div className="block-title">
                        <CreatedAt date={'1992-04-12'} />
                        <div className="title">
                            <div className="title-author">{'Les origines'}</div>
                            <div className="title-name">{`Classiques d'Animation\net autres films\n${total}`}</div>
                        </div>
                    </div>
                    <p>{description}</p>
                </div>
            </section>
        </div>
    )
}
