/*datas*/
import { ImgDefault } from './ImgDefault.js';

import { Exp600_Battlefield } from './Experiences/Exp600_Battlefield.js';

export const ImgPersonnage = (slug) => {
    let datas = [];
    if (slug === 'abby-mallard') { }
    if (slug === 'abu') { }
    if (slug === 'aladdin') { }
    if (slug === 'alberique') { }
    if (slug === 'alfredo-linguini') { }
    if (slug === 'alice') { }
    if (slug === 'anakin-skywalker') { }
    if (slug === 'anastasie') { }
    if (slug === 'anna') { }
    if (slug === 'ant-man') { }
    if (slug === 'ariel') { }
    if (slug === 'asha') { }
    if (slug === 'atchoum') { }
    if (slug === 'atta') { }
    if (slug === 'aurore') { }
    if (slug === 'baloo') { }
    if (slug === 'basile') { }
    if (slug === 'belle') { }
    if (slug === 'berlioz') { }
    if (slug === 'bernard') { }
    if (slug === 'bert') { }
    if (slug === 'bianca') { }
    if (slug === 'bibi-lapin') { }
    if (slug === 'big-ben') { }
    if (slug === 'black-panther') { }
    if (slug === 'blanche-neige') { }
    if (slug === 'bob') { }
    if (slug === 'boniface') { }
    if (slug === 'bouh') { }
    if (slug === 'bourricot') { }
    if (slug === 'bourriquet') { }
    if (slug === 'bunny') { }
    if (slug === 'buzz-lightyear') { }
    if (slug === 'c-3po') { }
    if (slug === 'capitaine-crochet') { }
    if (slug === 'capitaine-gantu') { }
    if (slug === 'captain-america') { }
    if (slug === 'captain-marvel') { }
    if (slug === 'carl-fredricksen') { }
    if (slug === 'cda-workers') { }
    if (slug === 'cendrillon') { }
    if (slug === 'chewbacca') { }
    if (slug === 'chicken-little') { }
    if (slug === 'clarabelle') { }
    if (slug === 'clarice') { }
    if (slug === 'claude-frollo') { }
    if (slug === 'coco-lapin') { }
    if (slug === 'colere') { }
    if (slug === 'crane-rouge') { }
    if (slug === 'crapule') { }
    if (slug === 'cruella-d-enfer') { }
    if (slug === 'daisy') { }
    if (slug === 'dingo') { }
    if (slug === 'docteur-facilier') { }
    if (slug === 'docteur-strange') { }
    if (slug === 'donald') { }
    if (slug === 'dormeur') { }
    if (slug === 'dory') { }
    if (slug === 'doug') { }
    if (slug === 'dr-hamsterviel') { }
    if (slug === 'duffy-the-disney-bear') { }
    if (slug === 'dumbo') { }
    if (slug === 'eddie-valiant') { }
    if (slug === 'edgar') { }
    if (slug === 'edna-mode') { }
    if (slug === 'elastigirl') { }
    if (slug === 'elsa') { }
    if (slug === 'emile') { }
    if (slug === 'empereur-kuzco') { }
    if (slug === 'esmeralda') { }
    if (slug === 'experience-010-felix') { }
    if (slug === 'experience-221-sparky') { }
    if (slug === 'experience-258-sample') { }
    if (slug === 'experience-624-angel') { }
    if (slug === 'experience-625-reuben') { }
    if (slug === 'experience-626-stitch') { }
    if (slug === 'experience-629-leroy') { }
    if (slug === 'ferb') { }
    if (slug === 'fifi') { }
    if (slug === 'flagada-jones') { }
    if (slug === 'flash-mcqueen') { }
    if (slug === 'flora') { }
    if (slug === 'flynn-rider') { }
    if (slug === 'frere-tuck') { }
    if (slug === 'frozone') { }
    if (slug === 'gadget-hackwrench') { }
    if (slug === 'gaetan-moliere') { }
    if (slug === 'gamora') { }
    if (slug === 'gaston') { }
    if (slug === 'gauchito') { }
    if (slug === 'gedeon') { }
    if (slug === 'george-sanderson') { }
    if (slug === 'geppetto') { }
    if (slug === 'germaine') { }
    if (slug === 'gouverneur-ratcliffe') { }
    if (slug === 'grincheux') { }
    if (slug === 'groot') { }
    if (slug === 'guepe') { }
    if (slug === 'gus-gus') { }
    if (slug === 'hades') { }
    if (slug === 'hercule') { }
    if (slug === 'honest-john') { }
    if (slug === 'horace') { }
    if (slug === 'iron-man') { }
    if (slug === 'jake-lockheart') { }
    if (slug === 'jack-skellington') { }
    if (slug === 'jack-sparrow') { }
    if (slug === 'jafar') { }
    if (slug === 'jane-porter') { }
    if (slug === 'jaq') { }
    if (slug === 'jasmine') { }
    if (slug === 'javotte') { }
    if (slug === 'jessica-rabbit') { }
    if (slug === 'jessie') { }
    if (slug === 'jiminy-cricket') { }
    if (slug === 'john-smith') { }
    if (slug === 'joie') { }
    if (slug === 'jose-carioca') { }
    if (slug === 'joyeux') { }
    if (slug === 'judy-hopps') { }
    if (slug === 'jumba-jookiba') { datas = Exp600_Battlefield() }
    if (slug === 'kala') { }
    if (slug === 'kenai') { }
    if (slug === 'kerchak') { }
    if (slug === 'kevin') { }
    if (slug === 'kidagakash-nedakh') { }
    if (slug === 'koda') { }
    if (slug === 'kristoff') { }
    if (slug === 'kronk') { }
    if (slug === 'kylo-ren') { }
    if (slug === 'la-bergere') { }
    if (slug === 'la-reine-de-coeur') { }
    if (slug === 'la-reine-elinor') { }
    if (slug === 'la-reine-sorciere') { }
    if (slug === 'lady-tremaine') { }
    if (slug === 'lapin-blanc') { }
    if (slug === 'le-chat-du-cheshire') { }
    if (slug === 'le-genie') { }
    if (slug === 'le-lievre-de-mars') { }
    if (slug === 'le-mandalorian-et-grogu') { }
    if (slug === 'le-roi-louie') { }
    if (slug === 'le-sergent') { }
    if (slug === 'le-sultan') { }
    if (slug === 'les-cartes-soldats') { }
    if (slug === 'les-pingouins') { }
    if (slug === 'lewis-robinson') { }
    if (slug === 'li-shang') { }
    if (slug === 'lilo') { }
    if (slug === 'loki') { }
    if (slug === 'lotso') { }
    if (slug === 'louis') { }
    if (slug === 'loulou') { }
    if (slug === 'lumiere') { }
    if (slug === 'mad-hatter') { }
    if (slug === 'malefique') { }
    if (slug === 'marcel') { }
    if (slug === 'marie') { }
    if (slug === 'marin') { }
    if (slug === 'mary-poppins') { }
    if (slug === 'max-goof') { }
    if (slug === 'meeko') { }
    if (slug === 'megara') { }
    if (slug === 'merida') { }
    if (slug === 'michael-goob-yagoobian') { }
    if (slug === 'mickey') { }
    if (slug === 'miguel') { }
    if (slug === 'milo-james-thatch') { }
    if (slug === 'minnie') { }
    if (slug === 'mirabel') { }
    if (slug === 'mitaine') { }
    if (slug === 'morse') { }
    if (slug === 'mouche') { }
    if (slug === 'mowgli') { }
    if (slug === 'mr-indestructible') { }
    if (slug === 'mulan') { }
    if (slug === 'mushu') { }
    if (slug === 'myster-mask') { }
    if (slug === 'naf-naf') { }
    if (slug === 'nemo') { }
    if (slug === 'nick-wilde') { }
    if (slug === 'nif-nif') { }
    if (slug === 'nouf-nouf') { }
    if (slug === 'olaf') { }
    if (slug === 'oogie-boogie') { }
    if (slug === 'orbitron') { }
    if (slug === 'ortensia') { }
    if (slug === 'oswald') { }
    if (slug === 'pain-d-epice') { }
    if (slug === 'panchito-pistoles') { }
    if (slug === 'panique') { }
    if (slug === 'panpan') { }
    if (slug === 'paquerette') { }
    if (slug === 'peine') { }
    if (slug === 'perla') { }
    if (slug === 'peter-pan') { }
    if (slug === 'petit-jean') { }
    if (slug === 'phasma') { }
    if (slug === 'philoctetes') { }
    if (slug === 'phineas') { }
    if (slug === 'phoebus') { }
    if (slug === 'picsou') { }
    if (slug === 'pikly') { }
    if (slug === 'pil-poil') { }
    if (slug === 'pimprenelle') { }
    if (slug === 'pinocchio') { }
    if (slug === 'pluto') { }
    if (slug === 'pocahontas') { }
    if (slug === 'porcinet') { }
    if (slug === 'prince-adam') { }
    if (slug === 'prince-eric') { }
    if (slug === 'prince-florian') { }
    if (slug === 'prince-hans') { }
    if (slug === 'prince-henri') { }
    if (slug === 'prince-jean') { }
    if (slug === 'prince-naveen') { }
    if (slug === 'prince-philippe') { }
    if (slug === 'prof') { }
    if (slug === 'quasimodo') { }
    if (slug === 'r2-d2') { }
    if (slug === 'rafiki') { }
    if (slug === 'raiponce') { }
    if (slug === 'remy') { }
    if (slug === 'rex') { }
    if (slug === 'rey') { }
    if (slug === 'riri') { }
    if (slug === 'robin-des-bois') { }
    if (slug === 'roger-rabbit') { }
    if (slug === 'russell') { }
    if (slug === 'sally') { }
    if (slug === 'shan-yu') { }
    if (slug === 'shelliemay') { }
    if (slug === 'sherif-de-nottingham') { }
    if (slug === 'simplet') { }
    if (slug === 'sorciere-rouge') { }
    if (slug === 'spiderman') { }
    if (slug === 'star-lord') { }
    if (slug === 'star-tours') { }
    if (slug === 'stormtrooper') { }
    if (slug === 'stromboli') { }
    if (slug === 'sulli') { }
    if (slug === 'suzy') { }
    if (slug === 'sven') { }
    if (slug === 'tarzan') { }
    if (slug === 'thor') { }
    if (slug === 'tiana') { }
    if (slug === 'tic-et-tac') { }
    if (slug === 'tigrou') { }
    if (slug === 'tilt') { }
    if (slug === 'timide') { }
    if (slug === 'timon') { }
    if (slug === 'tokina') { }
    if (slug === 'toulouse') { }
    if (slug === 'tristesse') { }
    if (slug === 'tweedle-dee-et-dum') { }
    if (slug === 'vaiana') { }
    if (slug === 'veuve-noire') { }
    if (slug === 'vincenzo-santorini') { }
    if (slug === 'volt') { }
    if (slug === 'wendy') { }
    if (slug === 'wilbur-robinson') { }
    if (slug === 'winnie') { }
    if (slug === 'woody') { }
    if (slug === 'yzma') { }
    return datas && datas.length > 0 ? datas : ImgDefault();
}