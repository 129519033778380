import { BrowserRouter } from 'react-router-dom';
import { AdminRoutes } from './AdminRoutes.js';
import { PublicRoutes } from './PublicRoutes.js';

export const Router = (props) => {
    return (
        <BrowserRouter>
            <PublicRoutes nav={props.nav} movies={props.movies} />
            <AdminRoutes />
        </BrowserRouter>
    )
}