import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Components/HostBackend.js';
import { FilterRoute } from '../../Components/Public/Filters/FilterRoute.js';
import { FilterCategory } from '../../Components/Public/Filters/FilterCategory.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { CreatedAt } from '../../Components/Public/Document/CreatedAt.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockInfos } from '../../Components/Public/Blocks/BlockInfos.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';

export const Univers = (props) => {
    const hosting = HostBackend();
    const [univers, setUnivers] = useState([]);
    const [attractions, setAttractions] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasUnivers = await fetch(`${hosting}/univers_by_id?id=${props.id}`);
            const univers = await datasUnivers.json();
            setUnivers(univers);
            const datasAttractions = await fetch(`${hosting}/attractions_by_univers?id=${props.id}`);
            const attractions = await datasAttractions.json();
            setAttractions(attractions);
        };
        fetchData();
    }, []);
    const categories = FilterCategory('univers', props.nav, props.id);
    return (
        <div className="univers">
            <Carrousel datas={attractions} />
            <section className="infos">
                <BreadCrumb />
                {univers.map((el, i) => {
                    const back = FilterRoute(el.uroute, 'univers');
                    return (
                        <div className="bloc-infos" key={`b${i}`}>
                            <BlockLogo slug={el.uslug} legend={el.uname} />
                            <BlockInfos back={back} univers={univers} attractions={attractions} categories={categories} count={[]} />
                        </div>
                    )
                })}
                {univers.map((el, i) => {
                    const back = FilterRoute(el.uroute, 'univers');
                    return (
                        <div className="description" key={`d${i}`}>
                            <CreatedAt date={'1992-04-12'} />
                            <div className="title">
                                <div className="title-author">{'Walt Disney Imagineering'}</div>
                                <div className="title-name">{el.uname}</div>
                                <a className="title-category" href={el.proute} alt={el.pname}>
                                    <div className="name">{el.pname}</div>
                                </a>
                            </div>
                            <p className="texte">{el.description}</p>
                        </div>
                    )
                })}
            </section>
        </div>
    )
}