/*
@props label string
@props datas array
*/

import { CountCategories } from '../Filters/CountCategories.js';

export const BlockCategories = (props) => {
    const count = CountCategories(props.attraction)
    return (
        <ul className="list">
            <li className="items-1">
                <div className="total">
                <div>{`${props.categories.length} categories`}</div>
                    <div>Attractions par</div>
                    <div>catégories</div>
                </div>
                {props.categories.map((c) => {
                    return (
                        <a key={`${c.cslug}`} className="item" href={c.croute} alt={c.cname}>
                            <div className="ccode">{c.ccode}</div>
                            <div className="cname">{`${c.cname} (${count[c.cslug]})`}</div>
                        </a>
                    )
                })}
            </li>
        </ul>
    )
}