import { useState, useEffect } from 'react';
import { FormAdminUpdate } from '../../Components/Admin/Form/FormAdminUpdate.js';
import { HostBackend } from '../../Components/HostBackend.js';
import { AutoCompleteRoute } from '../../Components/Admin/Functions/AutoCompleteRoute.js';

export const AdminUpdate = (props) => {
    const url = window.location.href;
    const id = url.includes('?') ? url.split('?')[1].slice(3,) : 1;
    const hostBackend = HostBackend();
    const [datas, setDatas] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasUpdate = await fetch(`${hostBackend}/admin/form/${props.uri}?id=${id}`);
            const datas = await datasUpdate.json();
            setDatas(datas);
        };
        fetchData();
    }, []);
    window.addEventListener('load', (ev) => {
        AutoCompleteRoute();
    });
    return (
        <div className="admin">
            <FormAdminUpdate datas={datas} uri={props.uri} />
            <a className="back" href={`/admin/entity/collection/${props.uri}`} alt="back">
                <i className="fa fa-reply"></i>
            </a>
        </div>
    )
}