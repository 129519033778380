export const GetTable = () => {
    let uri = window.location.href;
    let table = '';
    if (uri.includes('/admin/')) {
        if (uri.includes('category')) {
            table = 'category';
        } else if (uri.includes('origin')) {
            table = 'origin';
        } else if (uri.includes('outlinks')) {
            table = 'outlinks';
        } else {
            table = 'attraction';
        }
    } else {
        if (uri.includes('origin')) {
            table = 'origin';
        } else {
            table = 'attraction';
        }
    }
    return table;
}