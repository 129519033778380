/* datas */
import { Exp000_HouseholdHelpers } from '../Files/Experiences/Exp000_HouseholdHelpers.js';
import { Exp100_Disturbances } from '../Files/Experiences/Exp100_Disturbances.js';
import { Exp200_Technological } from '../Files/Experiences/Exp200_Technological.js';
import { Exp300_Psychological } from '../Files/Experiences/Exp300_Psychological.js';
import { Exp400_Mysterious } from '../Files/Experiences/Exp400_Mysterious.js';
import { Exp500_Environmental } from '../Files/Experiences/Exp500_Environmental.js';
import { Exp600_Battlefield } from '../Files/Experiences/Exp600_Battlefield.js';
import { Exp999_Others } from '../Files/Experiences/Exp999_Others.js';
/* document */
import { GetIconCode } from '../Functions/GetIconCode.js';

export const Experiences = () => {
    const uri = window.location.href;
    if (uri.includes('jumba-jookiba')) {
        const exp000 = Exp000_HouseholdHelpers();
        const exp100 = Exp100_Disturbances();
        const exp200 = Exp200_Technological();
        const exp300 = Exp300_Psychological();
        const exp400 = Exp400_Mysterious();
        const exp500 = Exp500_Environmental();
        const exp600 = Exp600_Battlefield();
        const exp999 = Exp999_Others();
        const total = exp000.length + exp100.length + exp200.length + exp300.length + exp400.length + exp500.length + exp600.length + exp999.length;
        const datas = [
            { title: 'Aide ménager', data: exp000 },
            { title: 'Perturbateurs', data: exp100 },
            { title: 'Technologiques', data: exp200 },
            { title: 'Psychologiques', data: exp300 },
            { title: 'Mistérieux', data: exp400 },
            { title: 'Environnemental', data: exp500 },
            { title: 'Apocalyptique', data: exp600 },
            { title: 'Others', data: exp999 },
        ];
        return (
            <div className="experiences">
                <div className="title-main">{`Expériences (${total})`}</div>
                <a className="outlink" href="https://www.deviantart.com/sketch-lampoon" alt="deviantart.com" target="_blank">
                    <div className="code"><i className={GetIconCode('LK')}></i></div>
                    <div className="name">Source: sketch-lampoon</div>
                </a>
                <a className="outlink" href="/experiences/stitch" alt="experiences">
                    <div className="code"><i className={GetIconCode('LK')}></i></div>
                    <div className="name">Liste des expériences</div>
                </a>
                {datas.map((items, i) => {
                    return (
                        <div key={`b-${i}`} className="block-list">
                            <div className="title-list">{`${items.title} (${items.data.length})`}</div>
                            <div className="listing">
                                {items.data.map((item, i) => {
                                    const str = item.split('/')[3].split('.')[0].replace('experience-', '');
                                    const code = str.split('-')[0];
                                    const name = str.slice(4, str.length);
                                    return (
                                        <div key={`i-${i}`} className="item">
                                            <div className="code">{code}</div>
                                            <div className="name">{name}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}