import { Routes, Route } from 'react-router-dom';
/* datas */
import { FilterParkAndUnivers } from '../Components/Public/Filters/FilterParkAndUnivers.js';
import { FilterCategory } from '../Components/Public/Filters/FilterCategory.js';
import { FilterOrigins } from '../Components/Public/Filters/FilterOrigins.js';
/* public routes */
import { NotFound } from '../Pages/Public/NotFound.js';
import { Home } from '../Pages/Public/Home.js';
import { Categories } from '../Pages/Public/Categories.js';
import { Category } from '../Pages/Public/Category.js';
import { Origins } from '../Pages/Public/Origins.js';
import { Origin } from '../Pages/Public/Origin.js';
import { Outlinks } from '../Pages/Public/Outlinks.js'
import { Park } from '../Pages/Public/Park.js';
import { Univers } from '../Pages/Public/Univers.js';
import { Attraction } from '../Pages/Public/Attraction.js';
import { Experiences } from '../Pages/Public/Experiences.js';

export const PublicRoutes = (props) => {
    const navigation = props.nav;
    const movies = props.movies;
    const categories = FilterCategory('home', navigation);
    const origins = FilterOrigins('home', navigation);
    const parks = FilterParkAndUnivers(navigation);
    const allAttractions = navigation;
    return (
        <Routes>
            <Route path="/" element={<Home nav={navigation} cat={categories} ori={origins} />} />
            <Route path="/categories" element={<Categories nav={navigation} categories={categories} />} />
            <Route path="/origines" element={<Origins nav={navigation} origins={origins} />} />
            <Route path="/experiences/stitch" element={<Experiences />} />
            <Route path="/liens" element={<Outlinks nav={navigation} />} />
            {categories.map((c, i) => {
                return (<Route key={`c${i}`} path={c.croute} element={<Category id={c.cid} name={c.cname} />} />)
            })}
            {movies.map((m, i) => {
                return (<Route key={`m${i}`} path={m.route} element={<Origin id={m.id} name={m.name} />} />)
            })}
            {parks.map((p, i) => {
                return (<Route key={`p${i}`} path={p.proute} element={<Park id={p.pid} nav={navigation} name={p.pname} slug={p.pslug} />} />)
            })}
            {parks.map((p) => {
                return (
                    p.univers.map((u, i) => {
                        return (
                            <Route key={`u${i}`} path={u.uroute} element={
                                <Univers id={u.uid} nav={navigation} pname={p.pname} uname={u.uname} slug={u.uslug} />
                            } />
                        )
                    })
                )
            })}
            {allAttractions.map((a, i) => {
                return (<Route key={`a${i}`} path={a.aroute} element={
                    <Attraction id={a.aid} pname={a.pname} uname={a.uname} name={a.aname} slug={a.aslug} />
                } />)
            })}
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}