import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  if (sessionStorage.getItem('token') === null) {
    return <Navigate to="/admin/connexion" />;
  } else {
    return <Outlet />;
  }
};

export default PrivateRoute;
