import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Components/HostBackend.js';
import { FilterHomeDatas } from '../../Components/Public/Filters/FilterHomeDatas.js';
import { FilterAttractions } from '../../Components/Public/Filters/FilterAttractions.js';
import { FilesImagesDefault } from '../../Components/Public/Files/FilesImagesDefault.js';
import { CountOrigins } from '../../Components/Public/Filters/CountOrigins.js';
import { CountUnivers } from '../../Components/Public/Filters/CountUnivers.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockInfos } from '../../Components/Public/Blocks/BlockInfos.js';
import { BlockOrigins } from '../../Components/Public/Blocks/BlockOrigins.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';

export const Origins = (props) => {
    const hosting = HostBackend();
    const [home, setDatas] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const homeDatas = await fetch(`${hosting}/home`);
            const home = await homeDatas.json();
            setDatas(home);
        };
        fetchData();
    }, []);
    const parkAndUnivers = FilterHomeDatas(home);
    const attraction = props.nav;
    const origins = props.origins;
    const collection = FilesImagesDefault();
    const countOrigins = CountOrigins(attraction);
    const countUnivers = CountUnivers(attraction);
    return (
        <div className="home">
            <Carrousel datas={collection} />
            <section className="infos">
                <BreadCrumb />
                <div className="bloc-infos">
                    <ul className="list">
                        <BlockOrigins label="home-origins" datas={origins} count={countOrigins} />
                    </ul>
                </div>
                {parkAndUnivers.map((p,i) => {
                    let park = null;
                    let univers = null;
                    let attractions = null;
                    if (p.pid === 1) {
                        park = parkAndUnivers[0];
                        attractions = FilterAttractions(attraction, p.pid);
                        univers = parkAndUnivers[0].univers;
                    } else {
                        park = parkAndUnivers[1];
                        attractions = FilterAttractions(attraction, p.pid);
                        univers = parkAndUnivers[1].univers;
                    }
                    return (
                        <div className="bloc-infos" key={`origins-${i}`}>
                            <BlockLogo href={p.proute} slug={p.pslug} legend={p.pname} />
                            <BlockInfos univers={univers} attractions={attractions} count={countUnivers} />
                        </div>
                    )
                })}
            </section>
        </div>
    )
}
