export const FrontLot = (slug) => {
    let datas;
    if (slug === 'bert') {
        datas = require.context('../../../../assets/images/attractions/studio/bert/img',true);
    }
    if (slug === 'dingo') {
        datas = require.context('../../../../assets/images/attractions/studio/dingo/img',true);
    }
    if (slug === 'disney-studio-1') {
        datas = require.context('../../../../assets/images/attractions/studio/disney-studio-1/img', true);
    }
    if (slug === 'les-pingouins') {
        datas = require.context('../../../../assets/images/attractions/studio/les-pingouins/img',true);
    }
    if (slug === 'mary-poppins') {
        datas = require.context('../../../../assets/images/attractions/studio/mary-poppins/img',true);
    }
    return datas.keys().map(image => datas(image));
}