import { Slugify } from './Slugify';

export const AutoComplete = () => {
    const href = window.location.href.split('/')[6];
    const uri = href.includes('?') ? href.slice(0,href.indexOf('?')) : href;
    const slug = document.getElementById('slug');
    const name = document.getElementById('name');
    const route = document.getElementById('route');
    if (slug && uri === 'attraction') {
        let park = document.querySelector('#id_park');
        let univers = document.querySelector('#id_univ');
        slug.addEventListener('click', (e) => {
            let selected_park = '';
            let selected_univers = '';
            let parks = park.selectedOptions;
            let universes = univers.selectedOptions;
            for (let i = 0; i < parks.length; i++) selected_park += Slugify(parks[i].label);
            for (let i = 0; i < universes.length; i++) selected_univers += Slugify(universes[i].label);
            slug.value = Slugify(name.value)
            route.value = `/park-${selected_park}/univers/${selected_univers}/attraction/${e.target.value}`;
        });
    }
    if (slug && uri === 'univers') {
        let park = document.querySelector('#id_park');
        slug.addEventListener('click', (e) => {
            let selected_park = '';
            let parks = park.selectedOptions;
            for (let i = 0; i < parks.length; i++) selected_park += Slugify(parks[i].label);
            slug.value = Slugify(name.value)
            route.value = `/park-${selected_park}/univers/${e.target.value}`;
        });
    }
    if (slug && uri === 'park') {
        slug.addEventListener('click', (e) => {
            slug.value = Slugify(name.value);
            route.value = `/park-${Slugify(name.value)}`;
        });
    }
    if (slug && uri === 'category') {
        slug.addEventListener('click', (e) => {
            slug.value = Slugify(name.value);
            route.value = `/categorie/${Slugify(name.value)}`;
        });
    }
    if (slug && uri === 'origin') {
        slug.addEventListener('click', (e) => {
            slug.value = Slugify(name.value);
            route.value = `/origine/${Slugify(name.value)}`;
        });
    }
    return;
}