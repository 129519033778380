export const Frontierland = (slug) => {
    let datas;
    if (slug === 'arbre-a-sorcieres') {
        datas = require.context('../../../../assets/images/attractions/park/arbre-a-sorcieres/img', true);
    }
    if (slug === 'big-thunder-mountain') {
        datas = require.context('../../../../assets/images/attractions/park/big-thunder-mountain/img', true);
    }
    if (slug === 'cedre-pleureur') {
        datas = require.context('../../../../assets/images/attractions/park/cedre-pleureur/img', true);
    }
    if (slug === 'fevier-d-amerique') {
        datas = require.context('../../../../assets/images/attractions/park/fevier-d-amerique/img', true);
    }
    if (slug === 'frontierland-playground') {
        datas = require.context('../../../../assets/images/attractions/park/frontierland-playground/img', true);
    }
    if (slug === 'le-roi-lion') {
        datas = require.context('../../../../assets/images/attractions/park/le-roi-lion/img', true);
    }
    if (slug === 'phantom-manor') {
        datas = require.context('../../../../assets/images/attractions/park/phantom-manor/img', true);
    }
    if (slug === 'rustler-roundup-shootin') {
        datas = require.context('../../../../assets/images/attractions/park/rustler-roundup-shootin/img', true);
    }
    if (slug === 'thunder-mesa-riverboat-landing') {
        datas = require.context('../../../../assets/images/attractions/park/thunder-mesa-riverboat-landing/img', true);
    }
    if (slug === 'jack-skellington') {
        datas = require.context('../../../../assets/images/attractions/park/jack-skellington/img', true);
    }
    if (slug === 'sally') {
        datas = require.context('../../../../assets/images/attractions/park/sally/img', true);
    }
    return datas.keys().map(image => datas(image));
}

