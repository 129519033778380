import { useState, useEffect } from 'react';
import { THead } from '../../Components/Admin/Collection/THead.js';
import { Entities } from '../../Components/Admin/Collection/Entities.js';
import { Attributes } from '../../Components/Admin/Collection/Attributes.js';
import { ButtonLogout } from '../../Components/Admin/Form/Buttons/ButtonLogout.js';
import { ButtonInsert } from '../../Components/Admin/Form/Buttons/ButtonInsert.js';
import { HostBackend } from '../../Components/HostBackend.js';

export const AdminCollection = (props) => {
    const hosting = HostBackend();
    const [datas, setDatas] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataAdmin = await fetch(`${hosting}/admin/collection/${props.uri}`);
            const datas = await dataAdmin.json();
            setDatas(datas);
        };
        fetchData();
    }, []);
    return (
        <div className="admin" key={1}>
            <ButtonInsert uri={props.uri} />
            <ButtonLogout />
            <div className="title">
                <div>ADMIN:<span>{props.uri} ({datas.length})</span></div>
            </div>
            <div className="list">
                <THead uri={props.uri} />
                <Entities datas={datas} uri={props.uri} />
                <Attributes datas={datas} uri={props.uri} />
            </div>
        </div>
    )
}