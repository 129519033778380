// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/attractions/aire-de-jeu/frontierland-playground/icon/frontierland-playground.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../images/attractions/aire-de-jeu/la-plage-des-pirates/icon/la-plage-des-pirates.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../images/attractions/aire-de-jeu/le-galion-des-pirates/icon/le-galion-des-pirates.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-frontierland-playground {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.icon-la-plage-des-pirates {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
.icon-le-galion-des-pirates {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/public/attractions/aire-de-jeu.css"],"names":[],"mappings":"AAAA;IACI,yDAA0H;AAC9H;AACA;IACI,yDAAoH;AACxH;AACA;IACI,yDAAsH;AAC1H","sourcesContent":[".icon-frontierland-playground {\n    background-image: url('../../../images/attractions/aire-de-jeu/frontierland-playground/icon/frontierland-playground.webp');\n}\n.icon-la-plage-des-pirates {\n    background-image: url('../../../images/attractions/aire-de-jeu/la-plage-des-pirates/icon/la-plage-des-pirates.webp');\n}\n.icon-le-galion-des-pirates {\n    background-image: url('../../../images/attractions/aire-de-jeu/le-galion-des-pirates/icon/le-galion-des-pirates.webp');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
