export const ToonStudio = (slug) => {
    let datas;
    if (slug === 'animation-academy') {
        datas = require.context('../../../../assets/images/attractions/studio/animation-academy/img', true);
    }
    if (slug === 'animation-celebration-frozen') {
        datas = require.context('../../../../assets/images/attractions/studio/animation-celebration-frozen/img', true);
    }
    if (slug === 'anna') {
        datas = require.context('../../../../assets/images/attractions/studio/anna/img', true);
    }
    if (slug === 'mr-indestructible') {
        datas = require.context('../../../../assets/images/attractions/studio/mr-indestructible/img',true);
    }
    if (slug === 'elastigirl') {
        datas = require.context('../../../../assets/images/attractions/studio/elastigirl/img',true);
    }
    if (slug === 'frozone') {
        datas = require.context('../../../../assets/images/attractions/studio/frozone/img',true);
    }
    if (slug === 'edna-mode') {
        datas = require.context('../../../../assets/images/attractions/studio/edna-mode/img',true);
    }
    if (slug === 'elsa') {
        datas = require.context('../../../../assets/images/attractions/studio/elsa/img', true);
    }
    if (slug === 'flash-mcqueen') {
        datas = require.context('../../../../assets/images/attractions/studio/flash-mcqueen/img',true);
    }
    if (slug === 'kristoff') {
        datas = require.context('../../../../assets/images/attractions/studio/kristoff/img', true);
    }
    if (slug === 'les-tapis-volants-d-aladdin') {
        datas = require.context('../../../../assets/images/attractions/studio/les-tapis-volants-d-aladdin/img', true);
    }
    if (slug === 'mickey-and-the-magician') {
        datas = require.context('../../../../assets/images/attractions/studio/mickey-and-the-magician/img', true);
    }
    if (slug === 'olaf') {
        datas = require.context('../../../../assets/images/attractions/studio/olaf/img', true);
    }
    if (slug === 'bob') {
        datas = require.context('../../../../assets/images/attractions/studio/bob/img', true);
    }
    if (slug === 'sulli') {
        datas = require.context('../../../../assets/images/attractions/studio/sulli/img', true);
    }
    if (slug === 'bouh') {
        datas = require.context('../../../../assets/images/attractions/studio/bouh/img', true);
    }
    if (slug === 'george-sanderson') {
        datas = require.context('../../../../assets/images/attractions/studio/george-sanderson/img', true);
    }
    if (slug === 'alberique') {
        datas = require.context('../../../../assets/images/attractions/studio/alberique/img', true);
    }
    if (slug === 'prince-hans') {
        datas = require.context('../../../../assets/images/attractions/studio/prince-hans/img', true);
    }
    if (slug === 'flynn-rider') {
        datas = require.context('../../../../assets/images/attractions/studio/flynn-rider/img', true);
    }
    if (slug === 'phineas') {
        datas = require.context('../../../../assets/images/attractions/studio/phineas/img', true);
    }
    if (slug === 'ferb') {
        datas = require.context('../../../../assets/images/attractions/studio/ferb/img', true);
    }
    if (slug === 'sven') {
        datas = require.context('../../../../assets/images/attractions/studio/sven/img', true);
    }
    return datas.keys().map(image => datas(image));
}