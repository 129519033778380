import { useState } from 'react';
import { FilterBkgColor } from '../Filters/FilterBkgColor';

export const BlockNavPublic = (props) => {
    const [show0, setShow0] = useState('hide');
    const [show1, setShow1] = useState('hide');
    const [show2, setShow2] = useState('hide');
    const bkgNav = FilterBkgColor();
    return (
        <div className={'navigation ' + bkgNav}>
            <div className="slider" onClick={() => { show0 === 'show' ? setShow0('hide') : setShow0('show'); }}>
                <i className="fa fa-bars"></i>
            </div>
            <a className="logo-nav" href="/" alt="Home">
                <i className="fa fa-home"></i>
            </a>
            <div className={'nav ' + show0}>
                {props.datas.map((p, i) => {
                    let show = (p.pid === 1) ? show1 : show2;
                    return (
                        <div key={`p${i}`} className="nav-parent">
                            <div id={p.pid} className={'link show ' + bkgNav} onClick={(e) => {
                                if (e.target.id === '1') { setShow1('show'); setShow2('hide') }
                                if (e.target.id === '2') { setShow2('show'); setShow1('hide') }
                            }}>{p.pname}
                            </div>
                            <div className={'nav-childs ' + show}>
                                {p.univers.map((u, i) => {
                                    return (<a key={`u${i}`} className={'link link-' + p.pid} href={u.uroute}>{u.uname}</a>)
                                })}
                            </div>
                        </div>
                    )
                })}
                <a className="link link-3" href="/categories" alt="categories">par Catégories</a>
                <a className="link link-3" href="/origines" alt="origines">par Origines</a>
                <a className="link link-3" href="/experiences/stitch" alt="experiences">Expériences Stitch</a>
                <a className="link link-3" href="/liens" alt="liens">Liens externes</a>
            </div>
        </div>
    )
}