import { useEffect, useState } from "react";
/* datas */
import { FilterBkgColor } from "../Filters/FilterBkgColor.js";
import { AnimCarrousel } from "../Functions/AnimCarrousel.js";
/* document */
import { Card } from "./Card.js";

export const Carrousel = (props) => {
    const incr = 300;
    const max = (incr * props.datas.length) - incr;
    const [index, setIndex] = useState(1);
    const [margX, setMargX] = useState(0);
    const [showPrev, setShowPrev] = useState('hide');
    const [showNext, setShowNext] = useState('show');
    useEffect(() => { AnimCarrousel(); });
    return (
        <section key="carrousel" id="carrousel" className={'carrousel ' + FilterBkgColor()}>
            <div className="block">
                <div id="prev" key="prev" className={'prev ' + showPrev} onClick={() => {
                    setIndex(index - 1);
                    setMargX(margX - incr);
                    if (margX < incr * 2) setShowPrev('hide');
                    if (index === 2) setShowNext('show')
                }}>
                    <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>
                </div>
                <div id="next" key="next" className={'next ' + showNext} onClick={() => {
                    const wrapper = document.getElementById('wrapper');
                    setIndex(index + 1);
                    setMargX(margX + incr);
                    if (margX >= incr) setShowPrev('show');
                    if (wrapper.offsetWidth >= max) setShowNext('hide');
                }}>
                    <i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
                </div>
                <div key="wrapper" id="wrapper" className="wrapper" style={{ marginLeft: -margX + 'px' }}>
                    {props.datas.map((item, i) => {
                        return <Card key={`card${i}`} item={item} />;
                    })}
                </div>
            </div>
            <div id="max" className="hide">{max}</div>
            <div id="incr" className="hide">{incr}</div>
        </section>
    );
};
