export const Discoveryland = (slug) => {
    let datas;
    if (slug === 'araucaria-araucana') {
        datas = require.context('../../../../assets/images/attractions/park/araucaria-araucana/img', true);
    }
    if (slug === 'arbre-aux-quarante-ecus') {
        datas = require.context('../../../../assets/images/attractions/park/arbre-aux-quarante-ecus/img', true);
    }
    if (slug === 'arbre-nuage-niwaki') {
        datas = require.context('../../../../assets/images/attractions/park/arbre-nuage-niwaki/img', true);
    }
    if (slug === 'autopia') {
        datas = require.context('../../../../assets/images/attractions/park/autopia/img', true);
    }
    if (slug === 'buzz-lightyear-last-blast') {
        datas = require.context('../../../../assets/images/attractions/park/buzz-lightyear-last-blast/img', true);
    }
    if (slug === 'les-mysteres-du-nautilus') {
        datas = require.context('../../../../assets/images/attractions/park/les-mysteres-du-nautilus/img', true);
    }
    if (slug === 'mickey-et-son-orchestre-philharmagique') {
        datas = require.context('../../../../assets/images/attractions/park/mickey-et-son-orchestre-philharmagique/img', true);
    }
    if (slug === 'minnie') {
        datas = require.context('../../../../assets/images/attractions/park/minnie/img', true);
    }
    if (slug === 'orbitron') {
        datas = require.context('../../../../assets/images/attractions/park/orbitron/img', true);
    }
    if (slug === 'star-tours') {
        datas = require.context('../../../../assets/images/attractions/park/star-tours/img', true);
    }
    if (slug === 'star-wars-hyperspace-mountain') {
        datas = require.context('../../../../assets/images/attractions/park/star-wars-hyperspace-mountain/img', true);
    }
    if (slug === 'starport') {
        datas = require.context('../../../../assets/images/attractions/park/starport/img', true);
    }
    if (slug === 'wollemia-nobilis') {
        datas = require.context('../../../../assets/images/attractions/park/wollemia-nobilis/img', true);
    }
    if (slug === 'anakin-skywalker') {
        datas = require.context('../../../../assets/images/attractions/park/anakin-skywalker/img', true);
    }
    if (slug === 'stormtrooper') {
        datas = require.context('../../../../assets/images/attractions/park/stormtrooper/img', true);
    }
    if (slug === 'chewbacca') {
        datas = require.context('../../../../assets/images/attractions/park/chewbacca/img', true);
    }
    if (slug === 'kylo-ren') {
        datas = require.context('../../../../assets/images/attractions/park/kylo-ren/img', true);
    }
    if (slug === 'le-mandalorian-et-grogu') {
        datas = require.context('../../../../assets/images/attractions/park/le-mandalorian-et-grogu/img', true);
    }
    if (slug === 'rey') {
        datas = require.context('../../../../assets/images/attractions/park/rey/img', true);
    }
    if (slug === 'c-3po') {
        datas = require.context('../../../../assets/images/attractions/park/c-3po/img', true);
    }
    if (slug === 'r2-d2') {
        datas = require.context('../../../../assets/images/attractions/park/r2-d2/img', true);
    }
    return datas.keys().map(image => datas(image));
}

