/* image */
import { FilesImagesDefault } from "./FilesImagesDefault.js";

import { AvengersCampus } from "./Studio/AvengersCampus.js";
import { FrontLot } from "./Studio/FrontLot.js";
import { ProductionCourtyard } from "./Studio/ProductionCourtyard.js";
import { ToonStudio } from "./Studio/ToonStudio.js";
import { WorldsOfPixar } from "./Studio/WorldsOfPixar.js";

export const FilesImagesStudio = (slug) => {
    let datas;
    const univers = window.location.href.split('/')[5];
    if (univers === 'avengers-campus') {
        datas = AvengersCampus(slug);
    }
    if (univers === 'front-lot') {
        datas = FrontLot(slug);
    }
    if (univers === 'production-courtyard') {
        datas = ProductionCourtyard(slug);
    }
    if (univers === 'toon-studio') {
        datas = ToonStudio(slug);
    }
    if (univers === 'worlds-of-pixar') {
        datas = WorldsOfPixar(slug);
    }
    return datas && datas.length > 0 ? datas : FilesImagesDefault();
}


