import { Slugify } from './Slugify';

export const AutoCompleteRoute = () => {
    let park = document.querySelector('#id_park');
    let univers = document.querySelector('#id_univ');
    let slug = document.getElementById('slug');
    if (slug && park && univers) {
        slug.addEventListener('click', (e) => {
            let selected_park = '';
            let selected_univers = '';
            let parks = park.selectedOptions;
            let universes = univers.selectedOptions;
            for (let i = 0; i < parks.length; i++) selected_park += Slugify(parks[i].label);
            for (let i = 0; i < universes.length; i++) selected_univers += Slugify(universes[i].label);
            let route = document.getElementById('route');
            route.value = `/park-${selected_park}/univers/${selected_univers}/attraction/${e.target.value}`;
        });
    }
    return;
}