import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Components/HostBackend.js';
import { ImgOrigin } from '../../Components/Public/Files/ImgOrigin.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { CreatedAt } from '../../Components/Public/Document/CreatedAt.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';
import { GetIconCode } from '../../Components/Public/Functions/GetIconCode.js';

export const Origin = (props) => {
    const hosting = HostBackend();
    const [movies, setMovies] = useState([]);
    const [attractions, setAttractions] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasMovies = await fetch(`${hosting}/origin?id=${props.id}`);
            const movies = await datasMovies.json();
            setMovies(movies);
            const datasAttractions = await fetch(`${hosting}/attractions_by_origin?id=${props.id}`);
            const attractions = await datasAttractions.json();
            setAttractions(attractions);
        };
        fetchData();
    }, []);
    const slug = movies[0] ? movies[0].slug : '';
    const collection = ImgOrigin(slug);
    const total = attractions.length > 0 ? `(${attractions.length} attractions)` : '';
    return (
        <div className="origin">
            <Carrousel datas={collection} />
            <BreadCrumb />
            <section className="infos">
                <div className="list">
                    {attractions.map((a, i) => {
                        return (
                            <div key={`a-${i}`} className="logo">
                                <a className="img" href={a.aroute} alt={a.aname}>
                                    <div className={'icon icon-' + a.aslug}></div>
                                </a>
                                <div className="legend">
                                    <div className="title">{a.aname}</div>
                                    <a className="title-category" href={`/categorie/${a.cslug}`} alt={a.cname}>
                                        <div className="name">{a.cname}</div>
                                    </a>
                                    <a className="title-origin" href={a.uroute} alt={a.uname}>
                                        <div className="name">{a.uname}</div>
                                    </a>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {movies.map((m, i) => {
                    return (
                        <div key={`m-${i}`} className="description">
                            <div className="block-title">
                                <CreatedAt date={m.created} />
                                <div className="title">
                                    <div className="title-author">{m.author}</div>
                                    <div className="title-name">{`${props.name}\n${total}`}</div>
                                    <a className="title-origin" href={'/origines'} alt={'origines'}>
                                        <div className="code"><i className={GetIconCode('OR')}></i></div>
                                        <div className="name">{'Origines'}</div>
                                    </a>
                                </div>
                            </div>
                            <p>{m.description}</p>
                            <a className="outlink" href={m.link_href} target="_blank" rel="noreferrer">
                                <div className="code"><i className={GetIconCode('LK')}></i></div>
                                <div className="name">{`Source: ${m.link_name}`}</div>
                            </a>
                        </div>
                    )
                })}
            </section>
        </div>
    )
}