import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Components/HostBackend.js';
import { FilesImagesOrigin } from '../../Components/Public/Files/FilesImagesOrigin.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { CreatedAt } from '../../Components/Public/Document/CreatedAt.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';

export const Origin = (props) => {
    const hosting = HostBackend();
    const [attractions, setAttractions] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasAttractions = await fetch(`${hosting}/attractions_by_origin?id=${props.id}`);
            const attractions = await datasAttractions.json();
            setAttractions(attractions);
        };
        fetchData();
    }, []);
    const ocreated = attractions[0] ? attractions[0].ocreated : '';
    const oauthor = attractions[0] ? attractions[0].oauthor : '';
    const description = attractions[0] ? attractions[0].description : '';
    const link_name = attractions[0] ? attractions[0].link_name : '';
    const link_href = attractions[0] ? attractions[0].link_href : '';
    const collection = FilesImagesOrigin();
    return (
        <div className="origin">
            <Carrousel datas={collection} />
            <section className="infos">
                <BreadCrumb />
                <div className="list">
                    {attractions.map((el, i) => {
                        return (
                            <a key={`origin-${i}`} href={el.aroute} alt={el.aname} className="logo">
                                <div className="img">
                                    <div className={'icon icon-' + el.aslug}></div>
                                </div>
                                <div className="park-name">{el.pname}</div>
                                <div className="univers-name">{el.uname}</div>
                                <div className="park-name">{'Origine'}</div>
                                <div className="univers-name">{el.oname}</div>
                                <div className="category-name">{el.cname}</div>
                                <div className="legend">{el.aname}</div>
                            </a>
                        )
                    })}
                </div>
                <div className="description">
                    <CreatedAt date={ocreated} />
                    <div className="title">
                        <div className="title-author">{oauthor}</div>
                        <div className="title-name">{`${props.name} (${attractions.length})`}</div>
                        <a className="title-origin" href={'/origines'} alt={'origines'}>
                        <div className="code">{'OR'}</div>
                        <div className="name">{'Origines'}</div>
                        </a>
                    </div>
                    <p className="texte">{description}</p>
                    <a className="outlink" href={link_href} target="_blank">
                        <div className="code">LK</div>
                        <div className="name">{`Source: ${link_name}`}</div>
                    </a>
                </div>
            </section>
        </div>
    )
}