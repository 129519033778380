export const Text = (props) => {
    const name = props.name;
    const value = props.value;
    const clas = props .class;
    return (
        <div key={name} className="row">
            <label htmlFor={name}>{name}</label>
            <input type="text" id={name} name={name} defaultValue={value} class={clas ? clas : 'null'} />
        </div>
    )
}