/*datas*/
import { ImgDefault } from './ImgDefault.js';

import { AMillionSplashesOfColours } from './Attractions/AMillionSplashesOfColours.js';
import { AdventureIsle } from './Attractions/AdventureIsle.js';
import { AliceEtLaReineDeCoeur } from './Attractions/AliceEtLaReineDeCoeur.js';
import { AliceSCuriousLabyrinth } from './Attractions/AliceSCuriousLabyrinth.js';
import { AnimationAcademy } from './Attractions/AnimationAcademy.js';
import { AnimationCelebrationFrozen } from './Attractions/AnimationCelebrationFrozen.js';
import { AraucariaAraucana } from './Attractions/AraucariaAraucana.js';
import { ArbreASorcieres } from './Attractions/ArbreASorcieres.js';
import { ArbreAuxQuaranteEcus } from './Attractions/ArbreAuxQuaranteEcus.js';
import { ArbreNuageNiwaki } from './Attractions/ArbreNuageNiwaki.js';
import { ArbresCarres } from './Attractions/ArbresCarres.js';
import { Autopia } from './Attractions/Autopia.js';
import { AvengersAssembleFlightForce } from './Attractions/AvengersAssembleFlightForce.js';
import { AvengersPowerTheNight } from './Attractions/AvengersPowerTheNight.js';
import { Bambouseraie } from './Attractions/Bambouseraie.js';
import { BigThunderMountain } from './Attractions/BigThunderMountain.js';
import { BlancheNeigeEtLesSeptNains } from './Attractions/BlancheNeigeEtLesSeptNains.js';
import { BuzzLightyearLastBlast } from './Attractions/BuzzLightyearLastBlast.js';
import { CarsQuatreRouesRally } from './Attractions/CarsQuatreRouesRally.js';
import { CarsRoadTrip } from './Attractions/CarsRoadTrip.js';
import { CaseyJrLePetitTrainDuCirque } from './Attractions/CaseyJrLePetitTrainDuCirque.js';
import { CedrePleureur } from './Attractions/CedrePleureur.js';
import { CeremonieHeroique } from './Attractions/CeremonieHeroique.js';
import { CrushSCoaster } from './Attractions/CrushSCoaster.js';
import { DiscoveryArcade } from './Attractions/DiscoveryArcade.js';
import { DisneyDreams } from './Attractions/DisneyDreams.js';
import { DisneyElectricalSkyParade } from './Attractions/DisneyElectricalSkyParade.js';
import { DisneyStartsOnParade } from './Attractions/DisneyStartsOnParade.js';
import { DisneyStudio1 } from './Attractions/DisneyStudio1.js';
import { DisneylandRailroad } from './Attractions/DisneylandRailroad.js';
import { DumboTheFlyingElephant } from './Attractions/DumboTheFlyingElephant.js';
import { FevierDAmerique } from './Attractions/FevierDAmerique.js';
import { FrontierlandPlayground } from './Attractions/FrontierlandPlayground.js';
import { HeroTrainingCenter } from './Attractions/HeroTrainingCenter.js';
import { HorseDrawnStreetcarts } from './Attractions/HorseDrawnStreetcarts.js';
import { IndianaJonesEtLeTempleDuPeril } from './Attractions/IndianaJonesEtLeTempleDuPeril.js';
import { ItSASmallWorld } from './Attractions/ItSASmallWorld.js';
import { LAllianceDesAvengers } from './Attractions/LAllianceDesAvengers.js';
import { LIncroyableSpiderman } from './Attractions/LIncroyableSpiderman.js';
import { LaCabaneDesRobinson } from './Attractions/LaCabaneDesRobinson.js';
import { LaGalerieDeLaBelleAuBoisDormant } from './Attractions/LaGalerieDeLaBelleAuBoisDormant.js';
import { LaPlageDesPirates } from './Attractions/LaPlageDesPirates.js';
import { LaTaniereDuDragon } from './Attractions/LaTaniereDuDragon.js';
import { LeCarrouselDeLancelot } from './Attractions/LeCarrouselDeLancelot.js';
import { LeChateauDeLaBelleAuBoisDormant } from './Attractions/LeChateauDeLaBelleAuBoisDormant.js';
import { LeGalionDesPirates } from './Attractions/LeGalionDesPirates.js';
import { LePassageEnchanteDAladdin } from './Attractions/LePassageEnchanteDAladdin.js';
import { LePaysDesContesDeFees } from './Attractions/LePaysDesContesDeFees.js';
import { LeRoiLion } from './Attractions/LeRoiLion.js';
import { LesGardiensDeLaGalaxieDanceChallenge } from './Attractions/LesGardiensDeLaGalaxieDanceChallenge.js';
import { LesGuerrieresDuWakanda } from './Attractions/LesGuerrieresDuWakanda.js';
import { LesJardinsFeeriquesDeMainStreetUsa } from './Attractions/LesJardinsFeeriquesDeMainStreetUsa.js';
import { LesMysteresDuNautilus } from './Attractions/LesMysteresDuNautilus.js';
import { LesTapisVolantsDAladdin } from'./Attractions/LesTapisVolantsDAladdin.js';
import { LesVoyagesDePinocchio } from './Attractions/LesVoyagesDePinocchio.js';
import { LibertyArcade } from './Attractions/LibertyArcade.js';
import { MadHatterSTeaCups } from './Attractions/MadHatterSTeaCups.js';
import { MainStreetVehicles } from './Attractions/MainStreetVehicles.js';
import { MeetMickey } from './Attractions/MeetMickey.js';
import { MickeyAndTheMagician } from './Attractions/MickeyAndTheMagician.js';
import { MickeyEtSonOrchestrePhilharmagique } from './Attractions/MickeyEtSonOrchestrePhilharmagique.js';
import { PavillonDesPrincesses } from './Attractions/PavillonDesPrincesses.js';
import { PeterPanSFlight } from './Attractions/PeterPanSFlight.js';
import { PhantomManor } from './Attractions/PhantomManor.js';
import { PiratesOfCaribbean } from './Attractions/PiratesOfCaribbean.js';
import { Ratatouille } from './Attractions/Ratatouille.js';
import { RCRacer } from './Attractions/RCRacer.js';
import { RustlerRoundupShootin } from './Attractions/RustlerRoundupShootin.js';
import { SlinkyDogZigzagSpin } from './Attractions/SlinkyDogZigzagSpin.js';
import { StarWarsHyperspaceMountain } from './Attractions/StarWarsHyperspaceMountain.js';
import { Starport } from './Attractions/Starport.js';
import { StitchLive } from './Attractions/StitchLive.js';
import { Tetrapanax } from './Attractions/Tetrapanax.js';
import { TheDisneyJuniorDreamFactory } from './Attractions/TheDisneyJuniorDreamFactory.js';
import { TheTwilightZoneTowerOfTerror } from './Attractions/TheTwilightZoneTowerOfTerror.js';
import { ThunderMesaRiverboatLanding } from './Attractions/ThunderMesaRiverboatLanding.js';
import { TogetherAPixarMusicalAdventure } from './Attractions/TogetherAPixarMusicalAdventure.js';
import { Topiaires } from './Attractions/Topiaires.js';
import { ToySoldiersParachutesDrop } from './Attractions/ToySoldiersParachutesDrop.js';
import { WebSlingers } from './Attractions/WebSlingers.js';
import { WollemiaNobilis } from './Attractions/WollemiaNobilis.js';

export const ImgAttraction = (slug) => {
    let datas = [];
    if (slug === 'a-million-splashes-of-colours') { datas = AMillionSplashesOfColours() }
    if (slug === 'adventure-isle') { datas = AdventureIsle() }
    if (slug === 'alice-et-la-reine-de-coeur') { datas = AliceEtLaReineDeCoeur() }
    if (slug === 'alice-s-curious-labyrinth') { datas = AliceSCuriousLabyrinth() }
    if (slug === 'animation-academy') { datas = AnimationAcademy() }
    if (slug === 'animation-celebration-frozen') { datas = AnimationCelebrationFrozen() }
    if (slug === 'araucaria-araucana') { datas = AraucariaAraucana() }
    if (slug === 'arbre-a-sorcieres') { datas = ArbreASorcieres() }
    if (slug === 'arbre-aux-quarante-ecus') { datas = ArbreAuxQuaranteEcus() }
    if (slug === 'arbre-nuage-niwaki') { datas = ArbreNuageNiwaki() }
    if (slug === 'arbres-carres') { datas = ArbresCarres() }
    if (slug === 'autopia') { datas = Autopia() }
    if (slug === 'avengers-assemble-flight-force') { datas = AvengersAssembleFlightForce() }
    if (slug === 'avengers-power-the-night') { datas = AvengersPowerTheNight() }
    if (slug === 'bambouseraie') { datas = Bambouseraie() }
    if (slug === 'big-thunder-mountain') { datas = BigThunderMountain() }
    if (slug === 'blanche-neige-et-les-sept-nains') { datas = BlancheNeigeEtLesSeptNains() }
    if (slug === 'buzz-lightyear-last-blast') { datas = BuzzLightyearLastBlast() }
    if (slug === 'cars-quatre-roues-rally') { datas = CarsQuatreRouesRally() }
    if (slug === 'cars-road-trip') { datas = CarsRoadTrip() }
    if (slug === 'casey-jr-le-petit-train-du-cirque') { datas = CaseyJrLePetitTrainDuCirque() }
    if (slug === 'cedre-pleureur') { datas = CedrePleureur() }
    if (slug === 'ceremonie-heroique') { datas = CeremonieHeroique() }
    if (slug === 'crush-s-coaster') { datas = CrushSCoaster() }
    if (slug === 'discovery-arcade') { datas = DiscoveryArcade() }
    if (slug === 'disney-dreams') { datas = DisneyDreams() }
    if (slug === 'disney-electrical-sky-parade') { datas = DisneyElectricalSkyParade() }
    if (slug === 'disney-starts-on-parade') { datas = DisneyStartsOnParade() }
    if (slug === 'disney-studio-1') { datas = DisneyStudio1() }
    if (slug === 'disneyland-railroad') { datas = DisneylandRailroad() }
    if (slug === 'dumbo-the-flying-elephant') { datas = DumboTheFlyingElephant() }
    if (slug === 'fevier-d-amerique') { datas = FevierDAmerique() }
    if (slug === 'frontierland-playground') { datas = FrontierlandPlayground() }
    if (slug === 'hero-training-center') { datas = HeroTrainingCenter() }
    if (slug === 'horse-drawn-streetcarts') { datas = HorseDrawnStreetcarts() }
    if (slug === 'indiana-jones-et-le-temple-du-peril') { datas = IndianaJonesEtLeTempleDuPeril() }
    if (slug === 'it-s-a-small-world') { datas = ItSASmallWorld() }
    if (slug === 'l-alliance-des-avengers') { datas = LAllianceDesAvengers() }
    if (slug === 'l-incroyable-spiderman') { datas = LIncroyableSpiderman() }
    if (slug === 'la-cabane-des-robinson') { datas = LaCabaneDesRobinson() }
    if (slug === 'la-galerie-de-la-belle-au-bois-dormant') { datas = LaGalerieDeLaBelleAuBoisDormant() }
    if (slug === 'la-plage-des-pirates') { datas = LaPlageDesPirates() }
    if (slug === 'la-taniere-du-dragon') { datas = LaTaniereDuDragon() }
    if (slug === 'le-carrousel-de-lancelot') { datas = LeCarrouselDeLancelot() }
    if (slug === 'le-chateau-de-la-belle-au-bois-dormant') { datas = LeChateauDeLaBelleAuBoisDormant() }
    if (slug === 'le-galion-des-pirates') { datas = LeGalionDesPirates() }
    if (slug === 'le-passage-enchante-d-aladdin') { datas = LePassageEnchanteDAladdin() }
    if (slug === 'le-pays-des-contes-de-fees') { datas = LePaysDesContesDeFees() }
    if (slug === 'le-roi-lion') { datas = LeRoiLion() }
    if (slug === 'les-gardiens-de-la-galaxie-dance-challenge') { datas = LesGardiensDeLaGalaxieDanceChallenge() }
    if (slug === 'les-guerrieres-du-wakanda') { datas = LesGuerrieresDuWakanda() }
    if (slug === 'les-jardins-feeriques-de-main-street-usa') { datas = LesJardinsFeeriquesDeMainStreetUsa() }
    if (slug === 'les-mysteres-du-nautilus') { datas = LesMysteresDuNautilus() }
    if (slug === 'les-tapis-volants-d-aladdin') { datas = LesTapisVolantsDAladdin() }
    if (slug === 'les-voyages-de-pinocchio') { datas = LesVoyagesDePinocchio() }
    if (slug === 'liberty-arcade') { datas = LibertyArcade() }
    if (slug === 'mad-hatter-s-tea-cups') { datas = MadHatterSTeaCups() }
    if (slug === 'main-street-vehicles') { datas = MainStreetVehicles() }
    if (slug === 'meet-mickey') { datas = MeetMickey() }
    if (slug === 'mickey-and-the-magician') { datas = MickeyAndTheMagician() }
    if (slug === 'mickey-et-son-orchestre-philharmagique') { datas = MickeyEtSonOrchestrePhilharmagique() }
    if (slug === 'pavillon-des-princesses') { datas = PavillonDesPrincesses() }
    if (slug === 'peter-pan-s-flight') {  datas = PeterPanSFlight() }
    if (slug === 'phantom-manor') { datas = PhantomManor() }
    if (slug === 'pirates-of-caribbean') { datas = PiratesOfCaribbean() }
    if (slug === 'ratatouille') { datas = Ratatouille() }
    if (slug === 'rc-racer') { datas = RCRacer() }
    if (slug === 'rustler-roundup-shootin') { datas = RustlerRoundupShootin() }
    if (slug === 'slinky-dog-zigzag-spin') { datas = SlinkyDogZigzagSpin() }
    if (slug === 'star-wars-hyperspace-mountain') { datas = StarWarsHyperspaceMountain() }
    if (slug === 'starport') { datas = Starport() }
    if (slug === 'stitch-live') { datas = StitchLive() }
    if (slug === 'tetrapanax') { datas = Tetrapanax() }
    if (slug === 'the-disney-junior-dream-factory') { datas = TheDisneyJuniorDreamFactory() }
    if (slug === 'the-twilight-zone-tower-of-terror') { datas = TheTwilightZoneTowerOfTerror() }
    if (slug === 'thunder-mesa-riverboat-landing') { datas = ThunderMesaRiverboatLanding() }
    if (slug === 'together-a-pixar-musical-adventure') { datas = TogetherAPixarMusicalAdventure() }
    if (slug === 'topiaires') { datas = Topiaires() }
    if (slug === 'toy-soldiers-parachutes-drop') { datas = ToySoldiersParachutesDrop() }
    if (slug === 'web-slingers') { datas = WebSlingers() }
    if (slug === 'wollemia-nobilis') { datas = WollemiaNobilis() }
    return datas && datas.length > 0 ? datas : ImgDefault();
}