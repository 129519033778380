import { useState, useEffect } from 'react';
import { FilterBkgColor } from '../Filters/FilterBkgColor.js';
/* datas */
import { HostBackend } from '../../HostBackend.js';
import { GetTable } from '../Functions/GetTable.js';

export const Finder = () => {
    const hosting = HostBackend();
    const uri = window.location.href;
    const bkgNav = FilterBkgColor();
    const table = GetTable();
    const [show0, setShow0] = useState('hide');
    const [show1, setShow1] = useState('hide');
    const [find, setFind] = useState('');
    const [result, setResult] = useState([]);
    useEffect(() => {
        if (find.length > 0) {
            const fetchtheData = async () => {
                const dataResult = await fetch(`${hosting}/find?ent=${table}&find=${find}`);
                const result = await dataResult.json();
                setResult(result);
            };
            fetchtheData();
        }
    }, [find]);
    if (!uri.includes('connexion') && !uri.includes('dashboard')) {
        let searchLabel = table ? `Recherche (${table})` : '';
        return (
            <div className={`finder ${bkgNav}`}>
                <div className="label" onClick={(e) => { show0 === 'hide' ? setShow0('show') : setShow0('hide'); setShow1('hide'); }}>
                    <div>{searchLabel}</div>
                    <i className="fa fa-search"></i>
                </div>
                <div className={`search ${show0}`}>
                    <input type="text" max="50" value={find} name="find" onChange={(e) => { setFind(e.target.value); setShow1('show'); }} />
                </div>
                <div className={`result ${show1}`}>
                    <div className="items">
                        {result.map((el, i) => {
                            if (uri.includes('admin') && sessionStorage.getItem('token') !== null) {
                                return (
                                    <a className="search-link" key={`f${i}`} href={`/admin/entity/update/${table}?id=${el.id}`}>{el.name}</a>
                                )
                            } else {
                                return (
                                    <a className="search-link" key={`f${i}`} href={el.route}>{el.name}</a>
                                )
                            }
                        })}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="finder"></div>
        )
    }
}