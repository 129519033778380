export const Header = () => {
    const uri = window.location.href;
    const params = uri.split('/');
    const bkgHeader = params[5] ? params[5] : params[3] ? params[3] : 'default-header';
    return (
        <header id="header" className={'header img-' + bkgHeader}>
            <a className="title" href="/" alt="Home">
                <div className="item1">Rencontre<div className="mickey"></div></div>
                <div className="item2">ta Souris</div>
                <div className="item3">à Disneyland Paris</div>
            </a>
            <div className="text">
                Vous trouverez dans ces pages la liste des attractions de Disneyland Paris 
                classées par parcs, Univers, Catégories et Origines...<br/>
                Chaque attraction est accompagnée d'un descriptif de son histoire
                et les informations utiles à son accès.
            </div>
        </header>
    )
}
