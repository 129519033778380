import './assets/css/public/fonts.css';
import './assets/css/public/app.css';
import './assets/css/public/park-disneyland.css';
import './assets/css/public/walt-disney-studios.css';
import './assets/css/admin/admin.css';
import { useState, useEffect } from 'react';
/* components */
import { Header } from './Components/Public/Document/Header.js';
import { Navigation } from './Components/Public/Document/Navigation.js';
import { FindAttractions } from './Components/Public/Document/FindAttractions.js';
import { BackTop } from './Components/Public/Document/BackTop.js';
import { Footer } from './Components/Public/Document/Footer.js';
import { LoadTitlePage } from './Components/Public/Functions/LoadTitlePage.js';
/* datas */
import { HostBackend } from './Components/HostBackend.js';
import { FilterParkAndUnivers } from './Components/Public/Filters/FilterParkAndUnivers.js';
/* routes */
import { Router } from './Routes/Router.js';

function App() {
    LoadTitlePage();
    const hosting = HostBackend();
    const uri = window.location.href;
    const main = uri.includes('admin') ? 'main administration' : 'main public';
    const [navigation, setNavigation] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataNavigation = await fetch(`${hosting}/navigation`);
            const navigation = await dataNavigation.json();
            setNavigation(navigation);
        };
        fetchData();
    }, []);
    const parkAndUnivers = FilterParkAndUnivers(navigation);
    return (
        <div id="main" className={main}>
            <Header />
            <Navigation datas={parkAndUnivers} uri={uri} />
            <FindAttractions />
            <Router nav={navigation} />
            <BackTop />
            <Footer />
        </div>
    );
}

export default App;
