/*
@props label string
@props datas array
*/
export const BlockOrigins = (props) => {
    return (
        <div className="list">
            <div className="total">
                <div>{`${props.datas.length} origines`}</div>
            </div>
            {props.datas.map((o) => {
                let counter = props.count ? props.count[o.oslug] : '-';
                return (
                    <a key={`${o.oslug}`} href={o.oroute} alt={o.oname} className="item">
                        <div className="cdate">{`${o.ocreated.split('-')[0]}`}</div>
                        <div className="cname">{`${o.oname} (${counter})`}</div>
                    </a>
                )
            })}
        </div>
    )
}