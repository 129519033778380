import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Components/HostBackend.js';
import { FilesImagesPark } from "../../Components/Public/Files/FilesImagePark.js";
import { FilesImagesStudio } from "../../Components/Public/Files/FilesImagesStudio.js";
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { CreatedAt } from '../../Components/Public/Document/CreatedAt.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockInfos } from '../../Components/Public/Blocks/BlockInfos.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';

export const Attraction = (props) => {
    const hostBackend = HostBackend();
    const uri = window.location.href;
    const slug = props.slug;
    const [attraction, setAttraction] = useState([]);
    useEffect(() => {
        const fetchtheData = async () => {
            const dataAttraction = await fetch(`${hostBackend}/attraction_by_id?id=${props.id}`);
            const attraction = await dataAttraction.json();
            setAttraction(attraction);
        };
        fetchtheData();
    }, []);
    const collection = uri.includes('park-disneyland') ? FilesImagesPark(slug) : FilesImagesStudio(slug);
    const link_name = attraction[0] ? attraction[0].link_name : '';
    const link_href = attraction[0] ? attraction[0].link_href : '';
    return (
        <div className="attraction">
            <Carrousel datas={collection} />
            <section className="infos">
                <BreadCrumb />
                {attraction.map((el, i) => {
                    return (
                        <div key={`b${i}`} className="bloc-infos">
                            <BlockLogo slug={el.aslug} legend={el.aname} origin={el.oname} />
                            <BlockInfos univers={[1]} attractions={attraction} />
                        </div>
                    )
                })}
                {attraction.map((el, i) => {
                    return (
                        <div key={`attraction-${i}`} className="description">
                            <CreatedAt date={el.created} />
                            <div className="title">
                                <div className="title-author">{el.author}</div>
                                <div className="title-name">{el.aname}</div>
                                <a className="title-category" href={'/categorie/' + el.cslug} alt={el.cname}>
                                    <div className="code">{el.ccode}</div>
                                    <div className="name">{el.cname}</div>
                                </a>
                                <a className="title-origin" href={'/origine/' + el.oslug} alt={el.oname}>
                                    <div className="code">OR</div>
                                    <div className="name">{el.oname}</div>
                                </a>
                            </div>
                            <p className="texte">{el.description}</p>
                            <a className="outlink" href={link_href} target="_blank">
                                <div className="code">LK</div>
                                <div className="name">{`Source: ${link_name}`}</div>
                            </a>
                        </div>
                    )
                })}
            </section>
        </div>
    )
}