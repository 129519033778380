import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Components/HostBackend.js';
import { ImgAttraction } from "../../Components/Public/Files/ImgAttraction.js";
import { ImgPersonnage } from '../../Components/Public/Files/ImgPersonnage.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { CreatedAt } from '../../Components/Public/Document/CreatedAt.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockInfos } from '../../Components/Public/Blocks/BlockInfos.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';
import { GetIconCode } from '../../Components/Public/Functions/GetIconCode.js';
import { Experiences } from '../../Components/Public/Document/Experiences.js';

export const Attraction = (props) => {
    const hosting = HostBackend();
    const [attraction, setAttraction] = useState([]);
    useEffect(() => {
        const fetchtheData = async () => {
            const dataAttraction = await fetch(`${hosting}/attraction_by_id?id=${props.id}`);
            const attraction = await dataAttraction.json();
            setAttraction(attraction);
        };
        fetchtheData();
    }, []);
    const cat = attraction && attraction[0] ? attraction[0].cid : 0;
    const slug = props.slug;
    const collection = cat === 10 ? ImgPersonnage(slug) : ImgAttraction(slug);
    return (
        <div className="attraction">
            <Carrousel datas={collection} />
            <BreadCrumb />
            <section className="infos">
                {attraction.map((el, i) => {
                    return (
                        <div key={`b-${i}`} className="bloc-infos">
                            <BlockLogo slug={el.aslug} legend={el.aname} origin={[el.oslug, el.oname]} category={[el.ccode, el.cname]} />
                            <BlockInfos univers={[1]} attractions={attraction} />
                        </div>
                    )
                })}
                {attraction.map((el, i) => {
                    return (
                        <div key={`a-${i}`} className="description">
                            <div className="block-title">
                                <CreatedAt date={el.created} />
                                <div className="title">
                                    <div className="title-author">{el.author}</div>
                                    <div className="title-name">{el.aname}</div>
                                    <a className="title-category" href={`/categorie/${el.cslug}`} alt={el.cname}>
                                        <div className="code"><i className={GetIconCode(el.ccode)}></i></div>
                                        <div className="name">{el.cname}</div>
                                    </a>
                                    <a className="title-origin" href={`/origine/${el.oslug}`} alt={el.oname}>
                                        <div className="code"><i className={GetIconCode('OR')}></i></div>
                                        <div className="name">{el.oname}</div>
                                    </a>
                                </div>
                            </div>
                            <p>{el.description}</p>
                            <a className="outlink" href={el.link_href} target="_blank">
                                <div className="code"><i className={GetIconCode('LK')}></i></div>
                                <div className="name">{`Source: ${el.link_name}`}</div>
                            </a>
                            <Experiences slug={slug} />
                        </div>
                    )
                })}
            </section>
        </div>
    )
}