import { ButtonDelete } from "../Form/Buttons/ButtonDelete.js";
import { ButtonUpdate } from "../Form/Buttons/ButtonUpdate.js";

export const Entities = (props) => {

    switch (props.uri) {
        case 'administrator':
        case 'user':
            return (
                props.datas.map((e,i) => {
                    return (
                        <div key={`u${i}`} className="rows">
                            <div className="id">{i+1}</div>
                            <div className="item">{e.firstname} ({e.id})</div>
                            <div className="item">{e.lastname}</div>
                            <ButtonDelete id={e.id} uri={props.uri} />
                            <ButtonUpdate id={e.id} uri={props.uri} />
                        </div>
                    )
                })
            )
        case 'attraction':
            return (
                props.datas.map((e,i) => {
                    return (
                        <div key={`a${i}`} className="rows">
                            <div className="id">{i+1}</div>
                            <div className="name">{e.name} <span>({e.id})</span></div>
                            <div className="item">{e.pname}</div>
                            <div className="item">{e.uname}</div>
                            <div className="item">{e.cname}</div>
                            <div className="item">{e.oname}</div>
                            <ButtonDelete id={e.id} uri={props.uri} />
                            <ButtonUpdate id={e.id} uri={props.uri} />
                        </div>
                    )
                })
            )
        case 'park':
        case 'univers':
            return (
                props.datas.map((e,i) => {
                    return (
                        <div key={`p${i}`} className="rows">
                            <div className="id">{i+1}</div>
                            <div className="name">{e.name} <span>({e.id})</span></div>
                            <div className="item">{e.route}</div>
                            <ButtonDelete id={e.id} uri={props.uri} />
                            <ButtonUpdate id={e.id} uri={props.uri} />
                        </div>
                    )
                }))
        case 'outlinks':
            return (
                props.datas.map((e,i) => {
                    return (
                        <div key={`o${i}`} className="rows">
                            <div className="id">{i+1}</div>
                            <div className="name">{e.name} <span>({e.id})</span></div>
                            <div className="item">{e.link}</div>
                            <ButtonDelete id={e.id} uri={props.uri} />
                            <ButtonUpdate id={e.id} uri={props.uri} />
                        </div>
                    )
                }))
        default:
            return (
                <></>
            )

    }
}
