import { FormAdminInsert } from '../../Components/Admin/Form/FormAdminInsert.js';
import { AutoComplete } from '../../Components/Admin/Functions/AutoComplete.js';

export const AdminInsert = (props) => {
    const uri = props.uri;
    window.addEventListener('load', (ev) => {
        AutoComplete();
    });
    return (
        <div className="admin">
            <FormAdminInsert uri={uri} />
            <a className="back" href={`/admin/entity/collection/${uri}`} alt="back">
                <i className="fa fa-reply"></i>
            </a>
        </div>
    )
}