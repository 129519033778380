import { Text } from '../../Form/Inputs/Text.js';
import { Select } from '../../Form/Inputs/Select.js';
import { Hidden } from '../../Form/Inputs/Hidden.js';
import { HostBackend } from '../../../HostBackend.js';
import { useState, useEffect } from 'react';

export const Park = (props) => {
    const hostBackend = HostBackend();
    const [descriptionChooser, setDescriptionChooser] = useState([]);
    const name = (props.e) ? props.e.name : '';
    const slug = (props.e) ? props.e.slug : '';
    const route = (props.e) ? props.e.route : '';
    const id_description = (props.e) ? props.e.id_description : '';
    const id = (props.e) ? props.e.id : '';
    useEffect(() => {
        const fetchData = async () => {
            const datasDescription = await fetch(`${hostBackend}/admin/collection/description`);
            const descriptionChooser = await datasDescription.json();
            setDescriptionChooser(descriptionChooser);
        };
        fetchData();
    }, []);
    return (
        <>
            <Text name="name" value={name} />
            <Text name="slug" value={slug} />
            <Text name="route" value={route} />
            <Select name="id_description" chooser={descriptionChooser} value={id_description} />
            <Hidden name="id" value={id} />
        </>
    )
}