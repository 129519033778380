export const WorldsOfPixar = (slug) => {
    let datas;
    if (slug === 'buzz-lightyear') {
        datas = require.context('../../../../assets/images/attractions/studio/buzz-lightyear/img', true);
    }
    if (slug === 'cars-quatre-roues-rally') {
        datas = require.context('../../../../assets/images/attractions/studio/cars-quatre-roues-rally/img', true);
    }
    if (slug === 'cars-road-trip') {
        datas = require.context('../../../../assets/images/attractions/studio/cars-road-trip/img', true);
    }
    if (slug === 'colere') {
        datas = require.context('../../../../assets/images/attractions/studio/colere/img', true);
    }
    if (slug === 'crush-s-coaster') {
        datas = require.context('../../../../assets/images/attractions/studio/crush-s-coaster/img', true);
    }
    if (slug === 'jessie') {
        datas = require.context('../../../../assets/images/attractions/studio/jessie/img', true);
    }
    if (slug === 'la-bergere') {
        datas = require.context('../../../../assets/images/attractions/studio/la-bergere/img',true);
    }
    if (slug === 'ratatouille') {
        datas = require.context('../../../../assets/images/attractions/studio/ratatouille/img', true);
    }
    if (slug === 'rc-racer') {
        datas = require.context('../../../../assets/images/attractions/studio/rc-racer/img', true);
    }
    if (slug === 'slinky-dog-zigzag-spin') {
        datas = require.context('../../../../assets/images/attractions/studio/slinky-dog-zigzag-spin/img', true);
    }
    if (slug === 'toy-soldiers-parachutes-drop') {
        datas = require.context('../../../../assets/images/attractions/studio/toy-soldiers-parachutes-drop/img', true);
    }
    if (slug === 'tristesse') {
        datas = require.context('../../../../assets/images/attractions/studio/tristesse/img', true);
    }
    if (slug === 'woody') {
        datas = require.context('../../../../assets/images/attractions/studio/woody/img', true);
    }
    if (slug === 'le-sergent') {
        datas = require.context('../../../../assets/images/attractions/studio/le-sergent/img', true);
    }
    return datas.keys().map(image => datas(image));
}