import { Email } from '../../Components/Admin/Form/Inputs/Email.js';
import { Password } from '../../Components/Admin/Form/Inputs/Password.js';
import { HostBackend } from '../../Components/HostBackend.js';

export const AdminConnexion = () => {
    const action = `${HostBackend()}/admin/administrator/login`;
    return (
        <div className="admin">
            <form className="form" action={action} method="post">
                <fieldset>
                    <legend>Admin</legend>
                    <Email value={''} />
                    <Password value={''} />
                    <button className="btn-submit">Submit</button>
                </fieldset>
            </form>
        </div>
    )
}