import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Components/HostBackend.js';
import { Exp000_HouseholdHelpers } from '../../Components/Public/Files/Experiences/Exp000_HouseholdHelpers.js';
import { Exp100_Disturbances } from '../../Components/Public/Files/Experiences/Exp100_Disturbances.js';
import { Exp200_Technological } from '../../Components/Public/Files/Experiences/Exp200_Technological.js';
import { Exp300_Psychological } from '../../Components/Public/Files/Experiences/Exp300_Psychological.js';
import { Exp400_Mysterious } from '../../Components/Public/Files/Experiences/Exp400_Mysterious.js';
import { Exp500_Environmental } from '../../Components/Public/Files/Experiences/Exp500_Environmental.js';
import { Exp600_Battlefield } from '../../Components/Public/Files/Experiences/Exp600_Battlefield.js';
import { Exp999_Others } from '../../Components/Public/Files/Experiences/Exp999_Others.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { CreatedAt } from '../../Components/Public/Document/CreatedAt.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { GetIconCode } from '../../Components/Public/Functions/GetIconCode.js';

export const Experiences = () => {
    const hosting = HostBackend();
    const [body, setBody] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasBody = await fetch(`${hosting}/experiences`);
            const body = await datasBody.json();
            setBody(body);
        };
        fetchData();
    }, []);
    const exp000 = Exp000_HouseholdHelpers();
    const exp100 = Exp100_Disturbances();
    const exp200 = Exp200_Technological();
    const exp300 = Exp300_Psychological();
    const exp400 = Exp400_Mysterious();
    const exp500 = Exp500_Environmental();
    const exp600 = Exp600_Battlefield();
    const exp999 = Exp999_Others();
    const total = exp000.length + exp100.length + exp200.length + exp300.length + exp400.length + exp500.length + exp600.length + exp999.length;
    const datas = [
        { title: 'Aide ménager', data: exp000 },
        { title: 'Perturbateurs', data: exp100 },
        { title: 'Technologiques', data: exp200 },
        { title: 'Psychologiques', data: exp300 },
        { title: 'Mistérieux', data: exp400 },
        { title: 'Environnemental', data: exp500 },
        { title: 'Apocalyptique', data: exp600 },
        { title: 'Others', data: exp999 },
    ];
    const description = body[0] ? body[0].description : '';
    const deviantart = 'https://www.deviantart.com/';
    const authors = ['bricerific43','sketch-lampoon','tombola1993','troytheartboy','tymore2000'];
    return (
        <div className="experiences">
            <BreadCrumb />
            <section className="infos">
                <div className="list">
                    <div className="title-main">{`Expériences de Jumba (${total})`}</div>
                    {datas.map((items, i) => {
                        return (
                            <div key={`b-${i}`} className="list">
                                <div className="title-list">{`SERIE ${i}00 - ${items.title} (${items.data.length})`}</div>
                                {items.data.map((item) => {
                                    const str = item.split('/')[3].split('.')[0].replace('experience-', '');
                                    const code = str.split('-')[0];
                                    const name = str.slice(4, str.length);
                                    return (
                                        <BlockLogo path={item} code={code} name={name} cat={items.title} />
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
                <div className="description">
                    <div className="block-title">
                        <CreatedAt date={'2002-06-22'} />
                        <div className="title">
                            <div className="title-author">{'Expériences Lilo & Stitch'}</div>
                            <div className="title-name">{'Remerciements'}</div>
                            {authors.map((author) => {
                                return (
                                    <a className="title-origin" href={`${deviantart}${author}`} alt={author}>
                                        <div className="code"><i className={GetIconCode('OR')}></i></div>
                                        <div className="name">{author}</div>
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                    <p>{description}</p>
                </div>
            </section>
        </div>
    )
}
