import { useState } from 'react';
import { GetAllEntities } from '../../Admin/Functions/GetAllEntities.js';

export const BlockNavAdmin = () => {
    const entities = GetAllEntities()[0];
    const attributes = GetAllEntities()[1];
    const [show, setShow] = useState('hide');
    return (
        <div className="nav-admin">
            <div className="slider" onClick={() => { show === 'show' ? setShow('hide') : setShow('show'); }}>
                <i className="fa fa-bars"></i>
            </div>
            <div className="legend-entity" id="entity"></div>
            <div className={`nav ${show}`}>
                <div className="nav-parent">
                    {entities.map((name, i) => {
                        return (
                            <a key={`e${i}`} className="link" href={`/admin/entity/collection/${name}`}>
                                {name}
                            </a>
                        )
                    })}
                </div>
                <div className="nav-parent">
                    {attributes.map((name, i) => {
                        return (
                            <a key={`a${i}`} className="link" href={`/admin/entity/collection/${name}`}>
                                {name}
                            </a>
                        )
                    })}
                </div>
            </div>
        </div >
    )
}