// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/attractions/point-d-interet/animation-academy/icon/animation-academy.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../images/attractions/point-d-interet/disney-studio-1/icon/disney-studio-1.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../images/attractions/point-d-interet/hero-training-center/icon/hero-training-center.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../../images/attractions/point-d-interet/meet-mickey/icon/meet-mickey.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../../../images/attractions/point-d-interet/pavillon-des-princesses/icon/pavillon-des-princesses.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../../../images/attractions/point-d-interet/starport/icon/starport.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-animation-academy {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.icon-disney-studio-1 {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
.icon-hero-training-center {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
.icon-meet-mickey {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
.icon-pavillon-des-princesses {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
.icon-starport {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/public/attractions/point-d-interet.css"],"names":[],"mappings":"AAAA;IACI,yDAAkH;AACtH;AACA;IACI,yDAA8G;AAClH;AACA;IACI,yDAAwH;AAC5H;AACA;IACI,yDAAsG;AAC1G;AACA;IACI,yDAA8H;AAClI;AACA;IACI,yDAAgG;AACpG","sourcesContent":[".icon-animation-academy {\n    background-image: url('../../../images/attractions/point-d-interet/animation-academy/icon/animation-academy.webp');\n}\n.icon-disney-studio-1 {\n    background-image: url('../../../images/attractions/point-d-interet/disney-studio-1/icon/disney-studio-1.webp');\n}\n.icon-hero-training-center {\n    background-image: url('../../../images/attractions/point-d-interet/hero-training-center/icon/hero-training-center.webp');\n}\n.icon-meet-mickey {\n    background-image: url('../../../images/attractions/point-d-interet/meet-mickey/icon/meet-mickey.webp');\n}\n.icon-pavillon-des-princesses {\n    background-image: url('../../../images/attractions/point-d-interet/pavillon-des-princesses/icon/pavillon-des-princesses.webp');\n}\n.icon-starport {\n    background-image: url('../../../images/attractions/point-d-interet/starport/icon/starport.webp');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
