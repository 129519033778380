import { useNavigate } from 'react-router-dom';

export const ButtonLogout = () => {
    const navigate = useNavigate();
    return (
        <button className="logout" onClick={() => {
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('user');
            navigate('/admin/connexion');
        }}>
            <i className="fa fa-power-off"></i>
        </button>
    )
}