import { Routes, Route } from 'react-router-dom';
/* datas */
import { GetAllEntities } from '../Components/Admin/Functions/GetAllEntities.js';
/* admin routes */
import PrivateRoute from '../Components/Admin/Functions/PrivateRoute.js';
import { AdminConnexion } from '../Pages/Admin/Connexion.js';
import { AdminDashboard } from '../Pages/Admin/AdminDashboard.js';
import { AdminCollection } from '../Pages/Admin/Collection.js';
import { AdminUpdate } from '../Pages/Admin/Update.js';
import { AdminInsert } from '../Pages/Admin/Insert.js';
import { Uploaded } from '../Pages/Admin/Uploaded.js';

export const AdminRoutes = () => {
    const entities = GetAllEntities()[0];
    const attributes = GetAllEntities()[1];
    return (
        <Routes>
            <Route path={'/admin/connexion'} element={<AdminConnexion />} />
            <Route path={'/admin/administrator/dashboard'} element={<AdminDashboard />} />
            <Route element={<PrivateRoute />}>
                {entities.map((name, i) => {
                    return (<Route key={`ec${i}`} path={`/admin/entity/collection/${name}`} element={<AdminCollection uri={name} />} />)
                })}
                {entities.map((name, i) => {
                    return (<Route key={`eu${i}`} path={`/admin/entity/update/${name}`} element={<AdminUpdate uri={name} />} />)
                })}
                {entities.map((name, i) => {
                    return (<Route key={`ei${i}`} path={`/admin/entity/insert/${name}`} element={<AdminInsert uri={name} />} />)
                })}
                {attributes.map((name, i) => {
                    return (<Route key={`ac${i}`} path={`/admin/entity/collection/${name}`} element={<AdminCollection uri={name} />} />)
                })}
                {attributes.map((name, i) => {
                    return (<Route key={`au${i}`} path={`/admin/entity/update/${name}`} element={<AdminUpdate uri={name} />} />)
                })}
                {attributes.map((name, i) => {
                    return (<Route key={`ai${i}`} path={`/admin/entity/insert/${name}`} element={<AdminInsert uri={name} />} />)
                })}
                <Route path={`/admin/uploaded/image`} element={<Uploaded />} />
            </Route>
        </Routes>
    )
}