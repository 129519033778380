import { FilterBkgColor } from '../Filters/FilterBkgColor.js';

export const BlockHomeTitle = (props) => {
    const bkg = FilterBkgColor();
    return (
        <div className={`home-title ${bkg}`}>
            <div className="item-1">
                <div>Disneyland</div>
                <div>Paris</div>
            </div>
            <div className="item-2">
                <div>{`${props.tt_park} parcs`}</div>
                <div>{`${props.tt_univers} univers`}</div>
                <div>{`${props.tt_attractions} attractions`}</div>
            </div>
        </div>
    )
}