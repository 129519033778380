export const Fantasyland = (slug) => {
    let datas;
    if (slug === 'alice-s-curious-labyrinth') {
        datas = require.context('../../../../assets/images/attractions/park/alice-s-curious-labyrinth/img', true);
    }
    if (slug === 'alice') {
        datas = require.context('../../../../assets/images/attractions/park/alice/img', true);
    }
    if (slug === 'ariel') {
        datas = require.context('../../../../assets/images/attractions/park/ariel/img', true);
    }
    if (slug === 'arbres-carres') {
        datas = require.context('../../../../assets/images/attractions/park/arbres-carres/img', true);
    }
    if (slug === 'aurore') {
        datas = require.context('../../../../assets/images/attractions/park/aurore/img', true);
    }
    if (slug === 'belle') {
        datas = require.context('../../../../assets/images/attractions/park/belle/img', true);
    }
    if (slug === 'blanche-neige') {
        datas = require.context('../../../../assets/images/attractions/park/blanche-neige/img', true);
    }
    if (slug === 'blanche-neige-et-les-sept-nains') {
        datas = require.context('../../../../assets/images/attractions/park/blanche-neige-et-les-sept-nains/img', true);
    }
    if (slug === 'bourriquet') {
        datas = require.context('../../../../assets/images/attractions/park/bourriquet/img', true);
    }
    if (slug === 'casy-jr-le-petit-train-du-cirque') {
        datas = require.context('../../../../assets/images/attractions/park/casy-jr-le-petit-train-du-cirque/img', true);
    }
    if (slug === 'cendrillon') {
        datas = require.context('../../../../assets/images/attractions/park/cendrillon/img', true);
    }
    if (slug === 'dumbo-the-flying-elephant') {
        datas = require.context('../../../../assets/images/attractions/park/dumbo-the-flying-elephant/img', true);
    }
    if (slug === 'pinocchio') {
        datas = require.context('../../../../assets/images/attractions/park/pinocchio/img', true);
    }
    if (slug === 'geppetto') {
        datas = require.context('../../../../assets/images/attractions/park/geppetto/img', true);
    }
    if (slug === 'crapule') {
        datas = require.context('../../../../assets/images/attractions/park/crapule/img', true);
    }
    if (slug === 'stromboli') {
        datas = require.context('../../../../assets/images/attractions/park/stromboli/img', true);
    }
    if (slug === 'it-s-a-small-world') {
        datas = require.context('../../../../assets/images/attractions/park/it-s-a-small-world/img', true);
    }
    if (slug === 'la-galerie-de-la-belle-au-bois-dormant') {
        datas = require.context('../../../../assets/images/attractions/park/la-galerie-de-la-belle-au-bois-dormant/img', true);
    }
    if (slug === 'la-reine-de-coeur') {
        datas = require.context('../../../../assets/images/attractions/park/la-reine-de-coeur/img', true);
    }
    if (slug === 'la-reine-sorciere') {
        datas = require.context('../../../../assets/images/attractions/park/la-reine-sorciere/img', true);
    }
    if (slug === 'la-taniere-du-dragon') {
        datas = require.context('../../../../assets/images/attractions/park/la-taniere-du-dragon/img', true);
    }
    if (slug === 'lapin-blanc') {
        datas = require.context('../../../../assets/images/attractions/park/lapin-blanc/img', true);
    }
    if (slug === 'le-carrousel-de-lancelot') {
        datas = require.context('../../../../assets/images/attractions/park/le-carrousel-de-lancelot/img', true);
    }
    if (slug === 'le-chateau-de-la-belle-au-bois-dormant') {
        datas = require.context('../../../../assets/images/attractions/park/le-chateau-de-la-belle-au-bois-dormant/img', true);
    }
    if (slug === 'le-pays-des-contes-de-fees') {
        datas = require.context('../../../../assets/images/attractions/park/le-pays-des-contes-de-fees/img', true);
    }
    if (slug === 'les-voyages-de-pinocchio') {
        datas = require.context('../../../../assets/images/attractions/park/les-voyages-de-pinocchio/img', true);
    }
    if (slug === 'mad-hatter') {
        datas = require.context('../../../../assets/images/attractions/park/mad-hatter/img', true);
    }
    if (slug === 'mad-hatter-s-tea-cups') {
        datas = require.context('../../../../assets/images/attractions/park/mad-hatter-s-tea-cups/img', true);
    }
    if (slug === 'max-goof') {
        datas = require.context('../../../../assets/images/attractions/park/max-goof/img', true);
    }
    if (slug === 'meet-mickey') {
        datas = require.context('../../../../assets/images/attractions/park/meet-mickey/img', true);
    }
    if (slug === 'merida') {
        datas = require.context('../../../../assets/images/attractions/park/merida/img', true);
    }
    if (slug === 'la-reine-elinor') {
        datas = require.context('../../../../assets/images/attractions/park/la-reine-elinor/img', true);
    }
    if (slug === 'mulan') {
        datas = require.context('../../../../assets/images/attractions/park/mulan/img', true);
    }
    if (slug === 'pavillon-des-princesses') {
        datas = require.context('../../../../assets/images/attractions/park/pavillon-des-princesses/img', true);
    }
    if (slug === 'peter-pan-s-flight') {
        datas = require.context('../../../../assets/images/attractions/park/peter-pan-s-flight/img', true);
    }
    if (slug === 'raiponce') {
        datas = require.context('../../../../assets/images/attractions/park/raiponce/img', true);
    }
    if (slug === 'tiana') {
        datas = require.context('../../../../assets/images/attractions/park/tiana/img', true);
    }
    if (slug === 'tigrou') {
        datas = require.context('../../../../assets/images/attractions/park/tigrou/img', true);
    }
    if (slug === 'topiaires') {
        datas = require.context('../../../../assets/images/attractions/park/topiaires/img', true);
    }
    if (slug === 'tweedle-dee-et-dum') {
        datas = require.context('../../../../assets/images/attractions/park/tweedle-dee-et-dum/img', true);
    }
    if (slug === 'le-lievre-de-mars') {
        datas = require.context('../../../../assets/images/attractions/park/le-lievre-de-mars/img', true);
    }
    if (slug === 'atchoum') {
        datas = require.context('../../../../assets/images/attractions/park/atchoum/img', true);
    }
    if (slug === 'grincheux') {
        datas = require.context('../../../../assets/images/attractions/park/grincheux/img', true);
    }
    if (slug === 'simplet') {
        datas = require.context('../../../../assets/images/attractions/park/simplet/img', true);
    }
    if (slug === 'prof') {
        datas = require.context('../../../../assets/images/attractions/park/prof/img', true);
    }
    if (slug === 'joyeux') {
        datas = require.context('../../../../assets/images/attractions/park/joyeux/img', true);
    }
    if (slug === 'timide') {
        datas = require.context('../../../../assets/images/attractions/park/timide/img', true);
    }
    if (slug === 'dormeur') {
        datas = require.context('../../../../assets/images/attractions/park/dormeur/img', true);
    }
    if (slug === 'prince-florian') {
        datas = require.context('../../../../assets/images/attractions/park/prince-florian/img', true);
    }
    if (slug === 'gedeon') {
        datas = require.context('../../../../assets/images/attractions/park/gedeon/img', true);
    }
    if (slug === 'honest-john') {
        datas = require.context('../../../../assets/images/attractions/park/honest-john/img', true);
    }
    if (slug === 'le-chat-du-cheshire') {
        datas = require.context('../../../../assets/images/attractions/park/le-chat-du-cheshire/img', true);
    }
    if (slug === 'malefique') {
        datas = require.context('../../../../assets/images/attractions/park/malefique/img', true);
    }
    return datas.keys().map(image => datas(image));
}

