export const ProductionCourtyard = (slug) => {
    let datas;
    if (slug === 'alice-et-la-reine-de-coeur') {
        datas = require.context('../../../../assets/images/attractions/studio/alice-et-la-reine-de-coeur/img', true);
    }
    if (slug === 'stitch-live') {
        datas = require.context('../../../../assets/images/attractions/studio/stitch-live/img', true);
    }
    if (slug === 'the-disney-junior-dream-factory') {
        datas = require.context('../../../../assets/images/attractions/studio/the-disney-junior-dream-factory/img', true);
    }
    if (slug === 'the-twilight-zone-tower-of-terror') {
        datas = require.context('../../../../assets/images/attractions/studio/the-twilight-zone-tower-of-terror/img', true);
    }
    if (slug === 'together-a-pixar-musical-adventure') {
        datas = require.context('../../../../assets/images/attractions/studio/together-a-pixar-musical-adventure/img', true);
    }
    if (slug === 'nemo') {
        datas = require.context('../../../../assets/images/attractions/studio/nemo/img', true);
    }
    if (slug === 'marin') {
        datas = require.context('../../../../assets/images/attractions/studio/marin/img', true);
    }
    if (slug === 'dory') {
        datas = require.context('../../../../assets/images/attractions/studio/dory/img', true);
    }
    if (slug === 'pil-poil') {
        datas = require.context('../../../../assets/images/attractions/studio/pil-poil/img', true);
    }
    if (slug === 'lotso') {
        datas = require.context('../../../../assets/images/attractions/studio/lotso/img', true);
    }
    if (slug === 'carl-fredricksen') {
        datas = require.context('../../../../assets/images/attractions/studio/carl-fredricksen/img', true);
    }
    if (slug === 'russell') {
        datas = require.context('../../../../assets/images/attractions/studio/russell/img', true);
    }
    if (slug === 'doug') {
        datas = require.context('../../../../assets/images/attractions/studio/doug/img', true);
    }
    if (slug === 'kevin') {
        datas = require.context('../../../../assets/images/attractions/studio/kevin/img', true);
    }
    if (slug === 'pil-poil') {
        datas = require.context('../../../../assets/images/attractions/studio/pil-poil/img', true);
    }
    if (slug === 'lotso') {
        datas = require.context('../../../../assets/images/attractions/studio/lotso/img', true);
    }
    if (slug === 'rex') {
        datas = require.context('../../../../assets/images/attractions/studio/rex/img', true);
    }
    if (slug === 'cda-workers') {
        datas = require.context('../../../../assets/images/attractions/studio/cda-workers/img', true);
    }
    return datas.keys().map(image => datas(image));
}

