import { Select } from '../../Form/Inputs/Select.js';
import { Text } from '../../Form/Inputs/Text.js';
import { Date } from '../../Form/Inputs/Date.js';
import { Hidden } from '../../Form/Inputs/Hidden.js';
import { useState, useEffect } from 'react';
import { HostBackend } from '../../../HostBackend.js';

export const Origin = (props) => {
    const hostBackend = HostBackend();
    const [descriptionChooser, setDescriptionChooser] = useState([]);
    const name = (props.e) ? props.e.name : '';
    const slug = (props.e) ? props.e.slug : '';
    const created = (props.e) ? props.e.created : '';
    const author = (props.e) ? props.e.author : '';
    const route = (props.e) ? props.e.route : '';
    const id_description = (props.e) ? props.e.id_description : '';
    const id = (props.e) ? props.e.id : '';
    useEffect(() => {
        const fetchData = async () => {
            const datasDescription = await fetch(`${hostBackend}/admin/collection/description`);
            const descriptionChooser = await datasDescription.json();
            setDescriptionChooser(descriptionChooser);
        };
        fetchData();
    }, []);
    return (
        <>
            <Text name="name" value={name} />
            <Text name="slug" value={slug} />
            <Date name="created" value={created} />
            <Text name="author" value={author} />
            <Text name="route" value={route} />
            <Select name="id_description" chooser={descriptionChooser} value={id_description} />
            <Hidden name="id" value={id} />
        </>
    )
}