/*
@props label string
@props datas array
*/

import { CountCategories } from '../Filters/CountCategories.js';
import { GetIconCode } from '../Functions/GetIconCode.js';

export const BlockCategories = (props) => {
    const count = CountCategories(props.attraction)
    return (
        <div className="list">
            <div className="total">
                <div>{`${props.categories.length} categories`}</div>
            </div>
            {props.categories.map((c) => {
                return (
                    <a key={`${c.cslug}`} className="item" href={c.croute} alt={c.cname}>
                        <div className="ccode" title={c.cname}><i className={GetIconCode(c.ccode)}></i></div>
                        <div className="cname">{`${c.cname} (${count[c.cslug]})`}</div>
                    </a>
                )
            })}
        </div>
    )
}