import { useState, useEffect } from 'react';
import { HostBackend } from '../../Components/HostBackend';

export const AdminDashboard = () => {
    const uri = window.location.href.split('?')[1];
    const id = uri.slice(-1);
    const action = `${HostBackend()}/admin/administrator/dashboard?id=${id}`;
    const [datas, setDatas] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataAdmin = await fetch(action);
            const datas = await dataAdmin.json();
            setDatas(datas);
        };
        fetchData();
    }, []);
    return (
        <div className="admin">
            {datas.map((el, i) => {
                sessionStorage.setItem('token', el.token);
                const date = new Date(el.date_current);
                let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
                const lastdate = date.toLocaleDateString('fr', options);
                return (
                    <div className="infos" key={i}>
                        <div className="row">
                            <label>Welcome</label>
                            <div className="value">{el.firstname + ' ' + el.lastname}</div>
                        </div>
                        <div className="row">
                            <label>Last connexion</label>
                            <div className="value">{lastdate}</div>
                        </div>
                        <div className="row">
                            <div className="value">
                                <a href="/admin/entity/collection/attraction">attraction</a>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}