export const Card = (props) => {
    const uri = window.location.href;
    if (uri.includes('attraction') && uri.includes('univers') && uri.includes('park')) {
        /* attraction */
        const title = uri.split('/')[7];
        return (
            <div key={`a`} className="card">
                <img className="img" src={props.item} title={title} alt={title} />
            </div>
        );
    } else if (uri.includes('park') && uri.includes('univers')) {
        /* Univers */
        return (
            <a key={`u`} className="card" href={props.item.aroute} alt={props.item.aname}>
                <div className={'img icon-' + props.item.aslug} id={props.item.aid}></div>
                <div className="legend">{props.item.aname}</div>
            </a>
        );
    } else if (uri.includes('park')) {
        /* Parks */
        return (
            <a key={`p`} className="card" href={props.item.uroute} alt={props.item.uname}>
                <div className={'img icon-' + props.item.uslug} id={props.item.uid}></div>
                <div className="legend">{props.item.uname}</div>
            </a>
        );
    } else if (uri.includes('origine') || uri.includes('categorie')) {
        /* origin & category*/
        const title = uri.split('/')[7];
        return (
            <div key={`o`} className="card">
                <img className="img" src={props.item} title={title} alt={title} />
            </div>
        );
    } else {
        /* home */
        return (
            <a key={`h`} className="card" href={props.item.aroute} alt={props.item.aname}>
                <div className={'img icon-' + props.item.aslug} id={props.item.aid}></div>
                <div className="legend">{props.item.aname}</div>
            </a>
        )
    }
}