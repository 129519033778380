import { Fields } from '../../../Components/Admin/Update/Fields.js';
import { HostBackend } from '../../../Components/HostBackend.js';

export const FormAdminInsert = (props) => {
    const action = `${HostBackend()}/admin/insert/${props.uri}`;
    return (
        <form className="form" action={action} method="POST">
            <fieldset>
                <legend>INSERT:<span>{props.uri}</span></legend>
                <Fields uri={props.uri} />
                <input type="submit" defaultValue="Insert" />
            </fieldset>
        </form>
    )
}