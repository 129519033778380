export const GetAllEntities = () => {
    const datas_entities = [];
    const datas_attributes = [];
    const entities = require.context('../Update/Entities', true);
    const attributes = require.context('../Update/Attributes', true);
    entities.keys()
        .filter(el => !el.includes('.js'))
        .map((file) => {
            datas_entities.push(file.slice(2,file.length).toLowerCase());
    });
    attributes.keys()
        .filter(el => !el.includes('.js'))
        .map((file) => {
            datas_attributes.push(file.slice(2,file.length).toLowerCase())
    });
    return [
        datas_entities, 
        datas_attributes
    ];
}