import { useState, useEffect } from 'react';
import { FilterBkgColor } from '../Filters/FilterBkgColor.js';
/* datas */
import { HostBackend } from '../../HostBackend.js';
import { GetTable } from '../Functions/GetTable.js';

export const Finder = () => {
    const hosting = HostBackend();
    const uri = window.location.href;
    const bkgNav = FilterBkgColor();
    const [show0, setShow0] = useState('hide');
    const [show1, setShow1] = useState('hide');
    const [table, setTable] = useState(GetTable('attraction'))
    const [find, setFind] = useState('');
    const [finder, setFinder] = useState([]);
    useEffect(() => {
        const fetchtheData = async () => {
            const dataFinder = await fetch(`${hosting}/find?ent=${table}&find=${find}`);
            const finder = await dataFinder.json();
            setFinder(finder);
        };
        fetchtheData();
    }, [table,find]);
    if (!uri.includes('connexion') && !uri.includes('dashboard')) {
        let searchLabel = table === 'origin' ? 'Recherche (Origine)' : 'Recherche (Attraction)';
        return (
            <div className={`finder ${bkgNav}`}>
                <div className="label" onClick={(e) => { show0 === 'hide' ? setShow0('show') : setShow0('hide'); setShow1('hide'); }}>
                    <div>{searchLabel}</div>
                    <i className="fa fa-search"></i>
                </div>
                <div className={`search ${show0}`}>
                    <input type="text" max="50" value={find} name="find" onChange={(e) => {
                        setTable(GetTable());
                        setFind(e.target.value);
                        setShow1('show');
                    }} />
                </div>
                <section className={`result ${show1}`}>
                    <h2>Résultat</h2>
                    <ul className="items" >
                        {finder.map((el, i) => {
                            if (uri.includes('admin') && sessionStorage.getItem('token') !== null) {
                                return (
                                    <li key={`f${i}`}><a href={`/admin/entity/update/${table}?id=${el.id}`}>{el.name}</a></li>
                                )
                            } else {
                                return (
                                    <li key={`f${i}`}><a href={el.route}>{el.name}</a></li>
                                )
                            }
                        })}
                    </ul>
                </section>
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}