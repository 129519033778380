import { BlockNavPublic } from '../Blocks/BlockNavPublic';
import { BlockNavAdmin } from '../Blocks/BlockNavAdmin';

export const Navigation = (props) => {
    const token = sessionStorage.getItem('token');
    if (props.uri.includes('admin/') && token) {
        return (<BlockNavAdmin />)
    } else {
        return (<BlockNavPublic datas={props.datas} />);
    }
}