import { useState, useEffect } from 'react';
import { FormAdminUpdate } from '../../Components/Admin/Form/FormAdminUpdate.js';
import { HostBackend } from '../../Components/HostBackend.js';
import { AutoComplete } from '../../Components/Admin/Functions/AutoComplete.js';

export const AdminUpdate = (props) => {
    const url = window.location.href;
    const uri = props.uri;
    const id = url.includes('?') ? url.split('?')[1].slice(3,) : 1;
    const hosting = HostBackend();
    const [datas, setDatas] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasUpdate = await fetch(`${hosting}/admin/form/${uri}?id=${id}`);
            const datas = await datasUpdate.json();
            setDatas(datas);
        };
        fetchData();
    }, []);
    window.addEventListener('load', (ev) => {
        AutoComplete();
    });
    return (
        <div className="admin">
            <FormAdminUpdate datas={datas} uri={uri} />
            <a className="back" href={`/admin/entity/collection/${uri}`} alt="back">
                <i className="fa fa-reply"></i>
            </a>
        </div>
    )
}