import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Components/HostBackend.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { CreatedAt } from '../../Components/Public/Document/CreatedAt.js';
import { GetIconCode } from '../../Components/Public/Functions/GetIconCode.js';

export const Category = (props) => {
    const hosting = HostBackend();
    const [attractions, setAttractions] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasAttractions = await fetch(`${hosting}/attractions_by_category?id=${props.id}`);
            const attractions = await datasAttractions.json();
            setAttractions(attractions);
        };
        fetchData();
    }, []);
    const description = attractions[0] ? attractions[0].description : '';
    const link_name = attractions[0] ? attractions[0].link_name : '';
    const link_href = attractions[0] ? attractions[0].link_href : '';
    const total = attractions.length > 0 ? `(${attractions.length} attractions)` : '';
    return (
        <div className="category">
            <BreadCrumb />
            <section className="infos">
                <div className="list">
                    {attractions.map((a, i) => {
                        return (
                            <div key={`category-${i}`} className="logo">
                                <a className="img" href={a.aroute} alt={a.aname}>
                                    <div className={'icon icon-' + a.aslug}></div>
                                </a>
                                <div className="legend">
                                    <div className="title">{a.aname}</div>
                                    <a className="title-category" href={`/categorie/${a.cslug}`} alt={a.cname}>
                                        <div className="name">{a.cname}</div>
                                    </a>
                                    <a className="title-origin" href={a.uroute} alt={a.uname}>
                                        <div className="name">{a.uname}</div>
                                    </a>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="description">
                    <div className="block-title">
                        <CreatedAt date={'1992-04-12'} />
                        <div className="title">
                            <div className="title-author">{'Walt Disney Imagineering'}</div>
                            <div className="title-name">{`${props.name}\n${total}`}</div>
                            <a className="title-origin" href={'/categories'} alt={'catégories'}>
                                <div className="code"><i className={GetIconCode('CT')}></i></div>
                                <div className="name">{'Categorie'}</div>
                            </a>
                        </div>
                    </div>
                    <p>{description}</p>
                    <a className="outlink" href={link_href} target="_blank" rel="noreferrer">
                        <div className="code"><i className={GetIconCode('LK')}></i></div>
                        <div className="name">{`Source: ${link_name}`}</div>
                    </a>
                </div>
            </section>
        </div>
    )
}