export const FilterOrigins = (parent, attractions, id) => {
    const collection = [];
    const map = new Map();
    for (const el of attractions) {
        if (parent === 'home') {
            if (!map.has(el.oslug)) {
                map.set(el.oslug, true);
                collection.push({ oid: el.oid, oname: el.oname, ocreated: el.ocreated, oslug: el.oslug, oroute: el.oroute });
            }
        }
        if (parent === 'park') {
            if (!map.has(el.oslug) && el.pid === id) {
                map.set(el.oslug, true);
                collection.push({ oid: el.oid, oname: el.oname, ocreated: el.ocreated, oslug: el.oslug, oroute: el.oroute });
            }
        }
        if (parent === 'univers') {
            if (!map.has(el.oslug) && el.uid === id) {
                map.set(el.oslug, true);
                collection.push({ oid: el.oid, oname: el.oname, ocreated: el.ocreated, oslug: el.oslug, oroute: el.oroute });
            }
        }
    }
    const origins = collection.sort(function(a, b) {
        return a.ocreated.localeCompare(b.ocreated)
    });
    return origins;
}

