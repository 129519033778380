export const MainStreet = (slug) => {
    let datas;
    if (slug === 'a-million-splashes-of-colours') {
        datas = require.context('../../../../assets/images/attractions/park/a-million-splashes-of-colours/img', true);
    }
    if (slug === 'asha') {
        datas = require.context('../../../../assets/images/attractions/park/asha/img', true);
    }
    if (slug === 'bunny') {
        datas = require.context('../../../../assets/images/attractions/park/bunny/img', true);
    }
    if (slug === 'coco-lapin') {
        datas = require.context('../../../../assets/images/attractions/park/coco-lapin/img', true);
    }
    if (slug === 'daisy') {
        datas = require.context('../../../../assets/images/attractions/park/daisy/img', true);
    }
    if (slug === 'discovery-arcade') {
        datas = require.context('../../../../assets/images/attractions/park/discovery-arcade/img', true);
    }
    if (slug === 'disney-dreams') {
        datas = require.context('../../../../assets/images/attractions/park/disney-dreams/img', true);
    }
    if (slug === 'disney-electrical-sky-parade') {
        datas = require.context('../../../../assets/images/attractions/park/disney-electrical-sky-parade/img', true);
    }
    if (slug === 'disney-starts-on-parade') {
        datas = require.context('../../../../assets/images/attractions/park/disney-starts-on-parade/img', true);
    }
    if (slug === 'disneyland-railroad') {
        datas = require.context('../../../../assets/images/attractions/park/disneyland-railroad/img', true);
    }
    if (slug === 'donald') {
        datas = require.context('../../../../assets/images/attractions/park/donald/img', true);
    }
    if (slug === 'emile') {
        datas = require.context('../../../../assets/images/attractions/park/emile/img', true);
    }
    if (slug === 'flora') {
        datas = require.context('../../../../assets/images/attractions/park/flora/img', true);
    }
    if (slug === 'gus-gus') {
        datas = require.context('../../../../assets/images/attractions/park/gus-gus/img', true);
    }
    if (slug === 'horse-drawn-streetcarts') {
        datas = require.context('../../../../assets/images/attractions/park/horse-drawn-streetcarts/img', true);
    }
    if (slug === 'jaq') {
        datas = require.context('../../../../assets/images/attractions/park/jaq/img', true);
    }
    if (slug === 'joie') {
        datas = require.context('../../../../assets/images/attractions/park/joie/img', true);
    }
    if (slug === 'judy-hopps') {
        datas = require.context('../../../../assets/images/attractions/park/judy-hopps/img', true);
    }
    if (slug === 'prince-adam') {
        datas = require.context('../../../../assets/images/attractions/park/prince-adam/img', true);
    }
    if (slug === 'les-jardins-feeriques-de-main-street-usa') {
        datas = require.context('../../../../assets/images/attractions/park/les-jardins-feeriques-de-main-street-usa/img', true);
    }
    if (slug === 'liberty-arcade') {
        datas = require.context('../../../../assets/images/attractions/park/liberty-arcade/img', true);
    }
    if (slug === 'main-street-vehicles') {
        datas = require.context('../../../../assets/images/attractions/park/main-street-vehicles/img', true);
    }
    if (slug === 'mickey') {
        datas = require.context('../../../../assets/images/attractions/park/mickey/img', true);
    }
    if (slug === 'miguel') {
        datas = require.context('../../../../assets/images/attractions/park/miguel/img', true);
    }
    if (slug === 'mirabel') {
        datas = require.context('../../../../assets/images/attractions/park/mirabel/img', true);
    }
    if (slug === 'nick-wilde') {
        datas = require.context('../../../../assets/images/attractions/park/nick-wilde/img', true);
    }
    if (slug === 'panpan') {
        datas = require.context('../../../../assets/images/attractions/park/panpan/img', true);
    }
    if (slug === 'paquerette') {
        datas = require.context('../../../../assets/images/attractions/park/paquerette/img', true);
    }
    if (slug === 'perla') {
        datas = require.context('../../../../assets/images/attractions/park/perla/img', true);
    }
    if (slug === 'picsou') {
        datas = require.context('../../../../assets/images/attractions/park/picsou/img', true);
    }
    if (slug === 'pimprenelle') {
        datas = require.context('../../../../assets/images/attractions/park/pimprenelle/img', true);
    }
    if (slug === 'pluto') {
        datas = require.context('../../../../assets/images/attractions/park/pluto/img', true);
    }
    if (slug === 'porcinet') {
        datas = require.context('../../../../assets/images/attractions/park/porcinet/img', true);
    }
    if (slug === 'prince-henri') {
        datas = require.context('../../../../assets/images/attractions/park/prince-henri/img', true);
    }
    if (slug === 'prince-philippe') {
        datas = require.context('../../../../assets/images/attractions/park/prince-philippe/img', true);
    }
    if (slug === 'remy') {
        datas = require.context('../../../../assets/images/attractions/park/remy/img', true);
    }
    if (slug === 'suzy') {
        datas = require.context('../../../../assets/images/attractions/park/suzy/img', true);
    }
    if (slug === 'vaiana') {
        datas = require.context('../../../../assets/images/attractions/park/vaiana/img', true);
    }
    if (slug === 'winnie') {
        datas = require.context('../../../../assets/images/attractions/park/winnie/img', true);
    }
    if (slug === 'lilo') {
        datas = require.context('../../../../assets/images/attractions/park/lilo/img', true);
    }
    if (slug === 'lady-tremaine') {
        datas = require.context('../../../../assets/images/attractions/park/lady-tremaine/img', true);
    }
    if (slug === 'docteur-facilier') {
        datas = require.context('../../../../assets/images/attractions/park/docteur-facilier/img', true);
    }
    if (slug === 'louis') {
        datas = require.context('../../../../assets/images/attractions/park/louis/img', true);
    }
    if (slug === 'prince-eric') {
        datas = require.context('../../../../assets/images/attractions/park/prince-eric/img', true);
    }
    if (slug === 'prince-naveen') {
        datas = require.context('../../../../assets/images/attractions/park/prince-naveen/img', true);
    }
    if (slug === 'shan-yu') {
        datas = require.context('../../../../assets/images/attractions/park/shan-yu/img', true);
    }
    if (slug === 'anastasie') {
        datas = require.context('../../../../assets/images/attractions/park/anastasie/img', true);
    }
    if (slug === 'javotte') {
        datas = require.context('../../../../assets/images/attractions/park/javotte/img', true);
    }
    if (slug === 'naf-naf') {
        datas = require.context('../../../../assets/images/attractions/park/naf-naf/img', true);
    }
    if (slug === 'nif-nif') {
        datas = require.context('../../../../assets/images/attractions/park/nif-nif/img', true);
    }
    if (slug === 'nouf-nouf') {
        datas = require.context('../../../../assets/images/attractions/park/nouf-nouf/img', true);
    }
    if (slug === 'duffy-the-disney-bear') {
        datas = require.context('../../../../assets/images/attractions/park/duffy-the-disney-bear/img', true);
    }
    if (slug === 'shelliemay') {
        datas = require.context('../../../../assets/images/attractions/park/shelliemay/img', true);
    }
    if (slug === 'berlioz') {
        datas = require.context('../../../../assets/images/attractions/park/berlioz/img', true);
    }
    if (slug === 'marie') {
        datas = require.context('../../../../assets/images/attractions/park/marie/img', true);
    }
    if (slug === 'toulouse') {
        datas = require.context('../../../../assets/images/attractions/park/toulouse/img', true);
    }
    if (slug === 'cruella-d-enfer') {
        datas = require.context('../../../../assets/images/attractions/park/cruella-d-enfer/img', true);
    }
    if (slug === 'jose-carioca') {
        datas = require.context('../../../../assets/images/attractions/park/jose-carioca/img', true);
    }
    if (slug === 'gauchito') {
        datas = require.context('../../../../assets/images/attractions/park/gauchito/img', true);
    }
    if (slug === 'bourricot') {
        datas = require.context('../../../../assets/images/attractions/park/bourricot/img', true);
    }
    if (slug === 'pocahontas') {
        datas = require.context('../../../../assets/images/attractions/park/pocahontas/img', true);
    }
    if (slug === 'john-smith') {
        datas = require.context('../../../../assets/images/attractions/park/john-smith/img', true);
    }
    if (slug === 'meeko') {
        datas = require.context('../../../../assets/images/attractions/park/meeko/img', true);
    }
    if (slug === 'gouverneur-ratcliffe') {
        datas = require.context('../../../../assets/images/attractions/park/gouverneur-ratcliffe/img', true);
    }
    if (slug === 'panchito-pistoles') {
        datas = require.context('../../../../assets/images/attractions/park/panchito-pistoles/img', true);
    }
    if (slug === 'clarabelle') {
        datas = require.context('../../../../assets/images/attractions/park/clarabelle/img', true);
    }
    if (slug === 'clarice') {
        datas = require.context('../../../../assets/images/attractions/park/clarice/img', true);
    }
    if (slug === 'horace') {
        datas = require.context('../../../../assets/images/attractions/park/horace/img', true);
    }
    if (slug === 'bernard') {
        datas = require.context('../../../../assets/images/attractions/park/bernard/img', true);
    }
    if (slug === 'bianca') {
        datas = require.context('../../../../assets/images/attractions/park/bianca/img', true);
    }
    if (slug === 'chicken-little') {
        datas = require.context('../../../../assets/images/attractions/park/chicken-little/img', false);
    }
    if (slug === 'abby-mallard') {
        datas = require.context('../../../../assets/images/attractions/park/abby-mallard/img', false);
    }
    if (slug === 'koda') {
        datas = require.context('../../../../assets/images/attractions/park/koda/img', false);
    }
    if (slug === 'kenai') {
        datas = require.context('../../../../assets/images/attractions/park/kenai/img', false);
    }
    if (slug === 'lumiere') {
        datas = require.context('../../../../assets/images/attractions/park/lumiere/img', false);
    }
    if (slug === 'big-ben') {
        datas = require.context('../../../../assets/images/attractions/park/big-ben/img', false);
    }
    if (slug === 'pain-d-epice') {
        datas = require.context('../../../../assets/images/attractions/park/pain-d-epice/img', false);
    }
    if (slug === 'quasimodo') {
        datas = require.context('../../../../assets/images/attractions/park/quasimodo/img', false);
    }
    if (slug === 'esmeralda') {
        datas = require.context('../../../../assets/images/attractions/park/esmeralda/img', false);
    }
    if (slug === 'claude-frollo') {
        datas = require.context('../../../../assets/images/attractions/park/claude-frollo/img', false);
    }
    if (slug === 'phoebus') {
        datas = require.context('../../../../assets/images/attractions/park/phoebus/img', false);
    }
    if (slug === 'bernard') {
        datas = require.context('../../../../assets/images/attractions/park/bernard/img', false);
    }
    if (slug === 'bianca') {
        datas = require.context('../../../../assets/images/attractions/park/bianca/img', false);
    }
    if (slug === 'jiminy-cricket') {
        datas = require.context('../../../../assets/images/attractions/park/jiminy-cricket/img', false);
    }
    if (slug === 'riri') {
        datas = require.context('../../../../assets/images/attractions/park/riri/img', false);
    }
    if (slug === 'fifi') {
        datas = require.context('../../../../assets/images/attractions/park/fifi/img', false);
    }
    if (slug === 'loulou') {
        datas = require.context('../../../../assets/images/attractions/park/loulou/img', false);
    }
    if (slug === 'robin-des-bois') {
        datas = require.context('../../../../assets/images/attractions/park/robin-des-bois/img', false);
    }
    if (slug === 'prince-jean') {
        datas = require.context('../../../../assets/images/attractions/park/prince-jean/img', false);
    }
    if (slug === 'sherif-de-nottingham') {
        datas = require.context('../../../../assets/images/attractions/park/sherif-de-nottingham/img', false);
    }
    if (slug === 'frere-tuck') {
        datas = require.context('../../../../assets/images/attractions/park/frere-tuck/img', false);
    }
    if (slug === 'petit-jean') {
        datas = require.context('../../../../assets/images/attractions/park/petit-jean/img', false);
    }
    if (slug === 'volt') {
        datas = require.context('../../../../assets/images/attractions/park/volt/img', false);
    }
    if (slug === 'mitaine') {
        datas = require.context('../../../../assets/images/attractions/park/mitaine/img', false);
    }
    if (slug === 'experience-624-angel') {
        datas = require.context('../../../../assets/images/attractions/park/experience-624-angel/img', false);
    }
    if (slug === 'experience-625-reuben') {
        datas = require.context('../../../../assets/images/attractions/park/experience-625-reuben/img', false);
    }
    if (slug === 'experience-626-stitch') {
        datas = require.context('../../../../assets/images/attractions/park/experience-626-stitch/img', false);
    }
    if (slug === 'experience-627-evil') {
        datas = require.context('../../../../assets/images/attractions/park/experience-627-evil/img', false);
    }
    if (slug === 'experience-629-leroy') {
        datas = require.context('../../../../assets/images/attractions/park/experience-629-leroy/img', false);
    }
    if (slug === 'experience-010-felix') {
        datas = require.context('../../../../assets/images/attractions/park/experience-010-felix/img', false);
    }
    if (slug === 'experience-221-sparky') {
        datas = require.context('../../../../assets/images/attractions/park/experience-221-sparky/img', false);
    }
    if (slug === 'experience-258-sample') {
        datas = require.context('../../../../assets/images/attractions/park/experience-258-sample/img', false);
    }
    if (slug === 'capitaine-gantu') {
        datas = require.context('../../../../assets/images/attractions/park/capitaine-gantu/img', false);
    }
    if (slug === 'dr-hamsterviel') {
        datas = require.context('../../../../assets/images/attractions/park/dr-hamsterviel/img', false);
    }
    if (slug === 'jumba-jookiba') {
        datas = require.context('../../../../assets/images/attractions/park/jumba-jookiba/img', false);
    }
    if (slug === 'pikly') {
        datas = require.context('../../../../assets/images/attractions/park/pikly/img', false);
    }
    if (slug === 'empereur-kuzco') {
        datas = require.context('../../../../assets/images/attractions/park/empereur-kuzco/img', false);
    }
    if (slug === 'kronk') {
        datas = require.context('../../../../assets/images/attractions/park/kronk/img', false);
    }
    if (slug === 'yzma') {
        datas = require.context('../../../../assets/images/attractions/park/yzma/img', false);
    }
    if (slug === 'kidagakash-nedakh') {
        datas = require.context('../../../../assets/images/attractions/park/kidagakash-nedakh/img', false);
    }
    if (slug === 'milo-james-thatch') {
        datas = require.context('../../../../assets/images/attractions/park/milo-james-thatch/img', false);
    }
    if (slug === 'gaetan-moliere') {
        datas = require.context('../../../../assets/images/attractions/park/gaetan-moliere/img', false);
    }
    if (slug === 'vincenzo-santorini') {
        datas = require.context('../../../../assets/images/attractions/park/vincenzo-santorini/img', false);
    }
    if (slug === 'gadget-hackwrench') {
        datas = require.context('../../../../assets/images/attractions/park/gadget-hackwrench/img', false);
    }
    if (slug === 'gaston') {
        datas = require.context('../../../../assets/images/attractions/park/gaston/img', false);
    }
    if (slug === 'morse') {
        datas = require.context('../../../../assets/images/attractions/park/morse/img', false);
    }
    if (slug === 'les-cartes-soldats') {
        datas = require.context('../../../../assets/images/attractions/park/les-cartes-soldats/img', false);
    }
    if (slug === 'hercule') {
        datas = require.context('../../../../assets/images/attractions/park/hercule/img', false);
    }
    if (slug === 'megara') {
        datas = require.context('../../../../assets/images/attractions/park/megara/img', false);
    }
    if (slug === 'hades') {
        datas = require.context('../../../../assets/images/attractions/park/hades/img', false);
    }
    if (slug === 'philoctetes') {
        datas = require.context('../../../../assets/images/attractions/park/philoctetes/img', false);
    }
    if (slug === 'panique') {
        datas = require.context('../../../../assets/images/attractions/park/panique/img', false);
    }
    if (slug === 'peine') {
        datas = require.context('../../../../assets/images/attractions/park/peine/img', false);
    }
    if (slug === 'oogie-boogie') {
        datas = require.context('../../../../assets/images/attractions/park/oogie-boogie/img', false);
    }
    if (slug === 'flagada-jones') {
        datas = require.context('../../../../assets/images/attractions/park/flagada-jones/img', false);
    }
    if (slug === 'myster-mask') {
        datas = require.context('../../../../assets/images/attractions/park/myster-mask/img', false);
    }
    if (slug === 'tilt') {
        datas = require.context('../../../../assets/images/attractions/park/tilt/img', false);
    }
    if (slug === 'atta') {
        datas = require.context('../../../../assets/images/attractions/park/atta/img', false);
    }
    if (slug === 'marcel') {
        datas = require.context('../../../../assets/images/attractions/park/marcel/img', false);
    }
    return datas.keys().map(image => datas(image));
}

