export const AnimCarrousel = () => {
    let i = 0;
    const wrapper = document.getElementById('wrapper');
    const max = document.getElementById('max');
    const incr = document.getElementById('incr')
    const prev = document.getElementById('prev');
    const next = document.getElementById('next');
    const cards = document.querySelectorAll('.card');
    const animation = setInterval(startAnimation, 2000);
    function startAnimation() {
        let marg = parseInt(incr.textContent) * i;
        if (marg < parseInt(max.textContent)) {
            wrapper.style.marginLeft = - marg + 'px';
            prev.classList.remove('hide');
            prev.classList.add('show');
        } else {
            clearInterval(animation);
            prev.classList.remove('show');
            prev.classList.add('hide');
            wrapper.style.marginLeft = 0 + 'px';
        }
        i++;
    }
    prev.addEventListener('click', () => {
        clearInterval(animation);
        wrapper.style.marginLeft = 0 + 'px';
    });
    next.addEventListener('click', () => {
        clearInterval(animation);
        wrapper.style.marginLeft = 0 + 'px';
    });
    for (let card of cards) {
        card.addEventListener('mouseover', (e) => {
            // console.log(e.target.id);
        })
    }
}