export const Card = (props) => {
    const uri = window.location.href;
    if (uri.includes('attraction') && uri.includes('univers') && uri.includes('park')) {
        /* attraction */
        const path = props.item.split('.')[0];
        const legend = !path.includes('walt') ? path.split('/')[3] : ''; /* folder default img */
        let title;
        if (legend.includes('experience-')) {
            title  = legend.replace('experience-','').slice(4,legend.replace('experience-','').length).charAt(0).toUpperCase();
            title += legend.replace('experience-','').slice(5,legend.replace('experience-','').length);
            title += ` (${legend.replace('experience-','').slice(0,3)})`;
        } else {
            title = legend;
        }
        return (
            <div key={`a`} className="card">
                <img className="img" src={props.item} title={title} alt={title} />
                <div className="legend">{title}</div>
            </div>
        );
    } else if (uri.includes('park') && uri.includes('univers')) {
        /* Univers */
        return (
            <a key={`u`} className="card" href={props.item.aroute} alt={props.item.aname}>
                <div className={'img icon-' + props.item.aslug} id={props.item.aid}></div>
                <div className="legend">{props.item.aname}</div>
            </a>
        );
    } else if (uri.includes('park')) {
        /* Parks */
        return (
            <a key={`p`} className="card" href={props.item.uroute} alt={props.item.uname}>
                <div className={'img icon-' + props.item.uslug} id={props.item.uid}></div>
                <div className="legend">{props.item.uname}</div>
            </a>
        );
    } else if (uri.includes('origine') || uri.includes('categorie') || uri.includes('film')) {
        /* origin & category*/
        const title = uri.split('/')[7];
        return (
            <div key={`o`} className="card">
                <img className="img" src={props.item} title={title} alt={title} />
            </div>
        );
    } else {
        /* home */
        return (
            <a key={`h`} className="card" href={props.item.aroute} alt={props.item.aname}>
                <div className={'img icon-' + props.item.aslug} id={props.item.aid}></div>
                <div className="legend">{props.item.aname}</div>
            </a>
        )
    }
}