/*
@props label string
@props datas array
*/

import { GetIconCode } from '../Functions/GetIconCode.js';

export const BlockCollection = (props) => {
    /* page home */
    if (props.label === 'home-link') {
        const count = props.count;
        return (
            <div className="list">
                <div className="total">
                    {`${props.datas.length} univers`}
                </div>
                {props.datas.map((u, i) => {
                    return (
                        <a className="item" key={`h${i}`} href={u.uroute} alt={u.uname}>
                            <div className="cname">{`${u.uname} (${count[u.uslug]})`}</div>
                        </a>
                    )
                })}
            </div>
        )
    }
    /* page park */
    if (props.label === 'park-link') {
        const count = props.count;
        return (
            <div className="list">
                <div className="total">
                    {`${props.datas.length} univers`}
                </div>
                {props.datas.map((u, i) => {
                    return (
                        <a className="item" key={`p${i}`} href={u.uroute} alt={u.uname}>
                            <div className="cname">{`${u.uname} (${count[u.uslug]})`}</div>
                        </a>
                    )
                })}
            </div>
        )
    }
    /* page univers */
    if (props.label === 'univers-link') {
        return (
            <div className="list">
                <div className="total">
                    {`${props.datas.length} attractions`}
                </div>
                {props.datas.map((a, i) => {
                    return (
                        <a className="item" key={`u${i}`} href={a.aroute} alt={a.aname}>
                            <div className="ccode"><i className={GetIconCode(a.ccode)}></i></div>
                            <div className="cname">{a.aname}</div>
                        </a>
                    )
                })}
            </div>
        )
    }
    /* page attraction */
    if (props.label === 'attraction-link') {
        return (
            <div className="list">
                <div className="total">
                    {`${props.datas.length} attractions`}
                </div>
                {props.datas.map((a, i) => {
                    return (
                        <a className="item" key={`a${i}`} href={a.aroute} alt={a.aname}>
                            <div className="cname">{a.aname}</div>
                        </a>
                    )
                })}
            </div>
        )
    }
}