export const NotFound = () => {
    const uri = window.location.href;
    if (!uri.includes('admin')) {
        return (
            <section className="not-found">
                <div className="stitch"></div>
                <div className="not">404</div>
                <div className="found">Page Not Found</div>
            </section>
        )
    } else {
        return (
            <></>
        )
    }
}