export const BlockLabel = (props) => {
    const datas = props.datas ? props.datas : [];
    const label = props.label.toLowerCase();
    switch (props.label) {
        case 'Categorie':
        case 'Public':
        case 'Durée':
        case 'Hauteur':
        case 'Longueur':
        case 'Vitesse':
        case 'Capacité':
        case 'Evacuation':
            return (
                <div key={`${label}`} className="info-tech">
                    <div className="icon">
                        <div className={props.icon}></div>
                    </div>
                    <div className="legend">
                        <div className="label">{props.label}</div>
                        <div className="name">{props.name}</div>
                    </div>
                </div>
            )
        case 'Intérets':
        case 'Handicaps':
            return (
                <div key={`${label}`} className="info-tech">
                    <div className="icon">
                        <div className={props.icon}></div>
                    </div>
                    <div className="legend">
                        <div className="label">{props.label}</div>
                            {datas.map((el,i) => {
                                return (
                                    <div key={`ih${i}`}>
                                        <div className="name">{el.name}</div>
                                        <div className="describe">{el.description}</div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            )
        case 'Taille':
            return (
                <div key={`${label}`} className="info-tech">
                    <div className="icon">
                        <div className={props.icon}></div>
                    </div>
                    <div className="legend">
                        <div className="label">{props.name}</div>
                        <div className="name">{props.description}</div>
                    </div>
                </div>
            )
        default:
            return (
                <div key={`${label}`} className="info-tech">
                    <div className="icon">
                        <div className={props.icon}></div>
                    </div>
                    <div className="legend">
                        <div className="label">{props.name}</div>
                        <div className="name">{props.description}</div>
                    </div>
                </div>
            )
    }
    
}