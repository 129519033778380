export const BackTop = () => {
    window.addEventListener('load', () => {
        let backtop = document.getElementById('backtop');
        let head = document.getElementById('header');
        let lastPosition = 0;
        let ticking = false;
        function hideAndShow(scrollPos) {
            if(scrollPos > (head.offsetHeight*2)) {
                backtop.classList.remove('hide');
                backtop.classList.add('show');
            } else {
                backtop.classList.add('hide');
                backtop.classList.remove('show');
            };
        }
        document.addEventListener("scroll", (event) => {
            lastPosition = window.scrollY;
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    hideAndShow(lastPosition);
                    ticking = false;
                });
                ticking = true;
            }
        })
        backtop.addEventListener('click', () => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        })
    });
    return (
        <div id="backtop" className="backtop hide">
            <i className="fa fa-arrow-up"></i>
        </div>
    )
}