import { Select } from '../../Form/Inputs/Select.js';
import { Text } from '../../Form/Inputs/Text.js';
import { Textarea } from '../../Form/Inputs/Textarea.js';
import { Hidden } from '../../Form/Inputs/Hidden.js';
import { useState, useEffect } from 'react';
import { HostBackend } from '../../../HostBackend.js';

export const Description = (props) => {
    const hostBackend = HostBackend();
    const [sortChooser, setSortChooser] = useState([]);
    const [linkChooser, setLinkChooser] = useState([]);
    const name = (props.e) ? props.e.name : '';
    const description = (props.e) ? props.e.description : '';
    const id_sort = (props.e) ? props.e.id_sort : '';
    const id_link = (props.e) ? props.e.id_link : '';
    const id = (props.e) ? props.e.id : '';
    useEffect(() => {
        const fetchData = async () => {
            const datasSort = await fetch(`${hostBackend}/admin/collection/sort`);
            const sortChooser = await datasSort.json();
            setSortChooser(sortChooser);
            const datasLinks = await fetch(`${hostBackend}/admin/collection/outlinks`);
            const linkChooser = await datasLinks.json();
            setLinkChooser(linkChooser);
        };
        fetchData();
    }, []);
    return (
        <>
            <Select name="id_sort" chooser={sortChooser} value={id_sort} />
            <Text name="name" value={name} />
            <Textarea name="description" value={description} />
            <Select name="id_link" chooser={linkChooser} value={id_link} />
            <Hidden name="id" value={id} />
        </>
    )
}