/* images */
import { FilesImagesDefault } from "./FilesImagesDefault.js";

import { Adventureland } from "./Park/Adventureland.js";
import { Discoveryland } from "./Park/Discoveryland.js";
import { Fantasyland } from "./Park/Fantasyland.js";
import { Frontierland } from "./Park/Frontierland.js";
import { MainStreet } from "./Park/MainStreet.js";

export const FilesImagesPark = (slug) => {
    let datas;
    const univers = window.location.href.split('/')[5];
    if (univers === 'adventureland') {
        datas = Adventureland(slug);
    }
    if (univers === 'discoveryland') {
        datas = Discoveryland(slug);
    }
    if (univers === 'fantasyland') {
        datas = Fantasyland(slug);
    }
    if (univers === 'frontierland') {
        datas = Frontierland(slug);
    }
    if (univers === 'main-street-usa') {
        datas = MainStreet(slug);
    }
    return datas && datas.length > 0 ? datas : FilesImagesDefault();
}

