import { Fields } from '../Update/Fields.js';
import { HostBackend } from '../../HostBackend.js';

export const FormAdminUpdate = (props) => {
    const hosting = HostBackend();
    return (
        <form className="form" action={`${hosting}/admin/update/${props.uri}`} method="POST">
            <fieldset>
                <legend>UPDATE:<span>{props.uri}</span></legend>
                {props.datas.map((el) => {
                    return (
                        <Fields key={props.uri} uri={props.uri} e={el} />
                    )
                })}
                <input type="submit" defaultValue="Update" />
            </fieldset>
        </form>
    )
}