import { FormAdminInsert } from '../../Components/Admin/Form/FormAdminInsert.js';
import { AutoCompleteRoute } from '../../Components/Admin/Functions/AutoCompleteRoute.js';

export const AdminInsert = (props) => {
    window.addEventListener('load', (ev) => {
        AutoCompleteRoute();
    });

    return (
        <div className="admin">
            <FormAdminInsert uri={props.uri} />
            <a className="back" href={`/admin/entity/collection/${props.uri}`} alt="back">
                <i className="fa fa-reply"></i>
            </a>
        </div>
    )
}