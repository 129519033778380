import { BlockCollection } from './BlockCollection.js';
import { BlockCategories } from './BlockCategories.js';
import { BlockAttraction } from './BlockAttraction.js';

export const BlockInfos = (props) => {
    const uri = window.location.href;
    const univers = props.univers ? props.univers : [];
    const attractions = props.attractions ? props.attractions : [];
    const categories = props.categories ? props.categories : [];
    const count = props.count ? props.count : [];
    /* home page */
    if (!uri.includes('park') && !uri.includes('univers') && !uri.includes('attractions')) {
        return (
            <>
                <BlockCollection label="home-link" datas={univers} count={count} />
            </>
        )
    }
    /* park page */
    if (uri.includes('park') && !uri.includes('univers')) {
        return (
            <>
                <BlockCollection label="park-link" datas={univers} count={count} />
                <BlockCategories label="park-categories" categories={categories} attraction={attractions} />
                <BlockCollection label="univers-link" datas={attractions} />
            </>
        )
    }
    /* univers page */
    if (uri.includes('univers') && !uri.includes('attraction')) {
        return (
            <>
                <BlockCategories label="univers-categories" categories={categories} attraction={attractions} />
                <BlockCollection label="univers-link" datas={attractions} />
            </>
        )
    }
    /* attraction page */
    if (uri.includes('attraction')) {
        return (
            <>
                <BlockAttraction datas={attractions} />
            </>
        )

    }
}