import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Components/HostBackend.js';
/* document */
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';
import { CreatedAt } from '../../Components/Public/Document/CreatedAt.js';

export const Outlinks = (props) => {
    const hostBackend = HostBackend();
    const [outlinks, setOutlinks] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasOutlinks = await fetch(`${hostBackend}/outlinks`);
            const outlinks = await datasOutlinks.json();
            setOutlinks(outlinks);
        };
        fetchData();
    }, []);
    const collection = props.nav;
    const texte = outlinks[0] ? outlinks[0].description : '';
    return (
        <div className="outlinks">
            <Carrousel datas={collection} />
            <section className="infos">
                <BreadCrumb />
                <div className="list">
                    {outlinks.map((el, i) => {
                        return (
                            <a key={`outlinks-${i}`} href={el.link} alt={el.name} target="_blank" className="outlink">
                                <div className="ccode">{'LK'}</div>
                                <div className="cname">{`${el.name}`}</div>
                            </a>
                        )
                    })}
                </div>
                <div className="description">
                    <CreatedAt date={'1992-04-12'} />
                    <div className="title">
                        <div className="title-author">{'Liens externes'}</div>
                        <div className="title-name">{'Remerciements'}</div>
                    </div>
                    <p className="texte">{texte}</p>
                </div>

            </section>
        </div>
    )
}