export const BlockPrivileges = (props) => {
    let picto = 'picto';
    switch (props.label) {
        case 'premieraccess':
            return (
                <div className={`${props.label}`}>
                    {props.datas.map((el,i) => {
                        picto = el.name.includes('Sans') ? 'picto disable' : 'picto';
                        return (
                            <div key={`icon-${i}`} className={picto}>
                                <div className="fa-stack fa-lg">
                                    <div className="fa fa-square fa-stack-2x"></div>
                                    <div className={`fa ${props.icon} fa-stack-1x fa-inverse`}></div>
                                </div>
                                {el.name}
                            </div>
                        )
                    })}
                    {props.datas.map((el,i) => {
                        return (
                            <div key={`desc-${i}`} className="note">{el.description}</div>
                        )
                    })}
                </div>
            )
        case 'pmr':
        case 'singlerider':
            return (
                <div key={`lab-${props.label}`} className={props.label}>
                    <div className="picto">
                        <div className="fa-stack fa-lg">
                            <div className="fa fa-square fa-stack-2x"></div>
                            <div className={`fa ${props.icon} fa-stack-1x fa-inverse`}></div>
                        </div>
                        {props.name}
                    </div>
                    <div className="note">{props.description}</div>
                </div>
            )
        case 'photopass':
        default:
            return (
                <div key={`lab-${props.label}`} className={props.label}>
                    <div className="picto">
                        <div className={`fa ${props.icon}`}></div>
                        {props.name}
                    </div>
                    <div className="note">{props.description}</div>
                </div>
            )
    }
}